import React, {useState} from 'react';
import {ICategoryVideos, IVideoSettings} from "../VideoSettings";
import UpdateVideo from "../UpdateVideo/UpdateVideo";
import Modal from "../../../../../../../components/modal/modal";

interface IVideosList{
    videoList: IVideoSettings,
    category: ICategoryVideos[],
    deleteVideo: (currentVideo: IVideoSettings) => void
}

const VideoList:React.FC<IVideosList> = ({videoList, category, deleteVideo}) => {
    const [showUpdateVideoModal, setShowUpdateVideoModal] = useState(false)

    const closeModal = () => {
        setShowUpdateVideoModal(false)
    }

    return (
        <tr>
            <td className="applications-table__tbody-td">
                <iframe
                    src={videoList.video_url}
                    className='admin-video'
                    allowFullScreen
                    allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                    loading='lazy'
                >
                </iframe>
            </td>
            <td className="applications-table__tbody-td">{videoList.name}</td>
            <td className="applications-table__tbody-td">{videoList.category_name}</td>
            <td className="applications-table__tbody-td">
                <button
                    className="button updateBtn"
                    onClick={() => setShowUpdateVideoModal(true)}
                >
                    Обновить
                </button>
                {showUpdateVideoModal &&
                    <Modal
                        active={showUpdateVideoModal}
                        title='Обновление информации о видео'
                        сlose={closeModal}
                    >
                        <UpdateVideo
                            video={videoList}
                            category={category}
                            close={closeModal}
                        />
                    </Modal>
                }
                <button
                    className="button deleteBtn"
                    onClick={() => deleteVideo(videoList)}
                >
                    Удалить
                </button>
            </td>
        </tr>
        );
        };

        export default VideoList;