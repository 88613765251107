import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../constants/constants";
import { useSearchParams } from "react-router-dom";
import Logo from "../../../assets/logo.jpg";
import { Helmet } from "react-helmet-async";
const href =
  typeof window !== "undefined" ? window.location.href : "https://нцпти.рф/";

interface ForgotPassword {
  email: string;
}

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm<ForgotPassword>();
  const navigate = useNavigate();

  const submit: SubmitHandler<ForgotPassword> = async (data) => {
    try {
      const res = await fetch(`${baseUrl}user/forgot_password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (res.status === 200) {
        alert("На указанную почту отправлена ссылка для восстановления пароля");
        navigate("/");
      } else if (res.status === 401) {
        setError("root", { type: "custom", message: "" });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Helmet>
        <title>Восстановить пароль | НЦПТИ</title>
        <meta name="description" content="Страница восстановления пароля" />
        <meta property="og:title" content=">Восстановить пароль | НЦПТИ" />
        <meta
          property="og:description"
          content="Страница восстановления пароля"
        />
        <meta property="og:image" content={Logo} />
        <meta property="og:url" content={href} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="page">
        <form onSubmit={handleSubmit(submit)} className="login-form">
          <h1 className="login-title">
            Введите почту, на которую зарегистрирован аккаунт
          </h1>
          <input
            type="email"
            placeholder="Введите ваш Email"
            className="login-input"
            {...register("email", {
              required: true,
            })}
          />
          {errors.email && errors.email.type === "required" && (
            <p className="wrong-message">Это поле обязательно</p>
          )}
          {errors.root && (
            <p className="wrong-message">
              Пользователь с такой почтой не найден
            </p>
          )}
          <div className="btns">
            <button className="button login-btn">Отправить</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ForgotPassword;
