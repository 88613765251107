import React, {useState} from 'react';
import {baseUrl} from "../../../../../../constants/constants";
import Modal from "../../../../../../components/modal/modal";
import {IPrivateForums} from "../../PrivateForumsPage";
import UpdatePrivateForum from "../UpdatePrivateForum/UpdatePrivateForum";


interface IPForumsList{
    p_forums: IPrivateForums;
    deletePForum: (currentCourse: IPrivateForums) => void
}

const PrivateForumsList: React.FC<IPForumsList> = ({p_forums, deletePForum}) => {
    const [showUpdateModal, setShowUpdateModal] = useState(false)

    const closeModal = () => {
        setShowUpdateModal(false)
    }

    const forum_start_date = new Date(p_forums.start_date_training).toLocaleString('ru', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    });

    const forum_end_date = new Date(p_forums.end_date_training).toLocaleString('ru', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    });

    return (
        <section className='admin-settings__section'>
            <h2 className="admin-settings__h2">{p_forums.name}</h2>
            <p className="admin-settings__p">Описание: {p_forums.description}</p>
            <p className="admin-settings__p">{p_forums.forum_location}</p>
            <p className="admin-settings__p">{forum_start_date}</p>
            <p className="admin-settings__p">{forum_end_date}</p>
            <p className="admin-settings__p">{p_forums.private_link}</p>
            <div className='settings-buttons'>
                <button
                    className='button updateBtn'
                    onClick={() => setShowUpdateModal(true)}
                >
                    Обновить
                </button>
                {showUpdateModal &&
                    <Modal
                        active={showUpdateModal}
                        title='Обновите информацию о курсе'
                        сlose={closeModal}
                    >
                        <UpdatePrivateForum
                            p_fourm={p_forums}
                            close={closeModal}
                        />
                    </Modal>}
                <button
                    className='button deleteBtn'
                    onClick={() => {
                        deletePForum(p_forums)
                    }}
                >
                    Удалить
                </button>
            </div>
        </section>
    );
};

export default PrivateForumsList;