import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../constants/constants";
import { Helmet } from "react-helmet-async";
import Preview from "../../../assets/cards/team_.webp";
import Logo1 from "./assets/ncpti-logo-blue.png";
import Logo2 from "./assets/ncpti-logo-light-blue.png";
import Logo3 from "./assets/ncpti-logo-white.png";
import Invalid1 from "./assets/invalid/invalid1.webp";
import Invalid2 from "./assets/invalid/invalid2.webp";
import Invalid3 from "./assets/invalid/invalid3.webp";
import Invalid4 from "./assets/invalid/invalid4.webp";
import Invalid5 from "./assets/invalid/invalid5.webp";
import Invalid6 from "./assets/invalid/invalid6.webp";
import "./brandbook.scss";

const href =
  typeof window !== "undefined" ? window.location.href : "https://нцпти.рф/";

interface IBanner {
  id: number;
  name: string;
  file_url: string;
}

const BrandbookPage = () => {
  const [banner, setBanner] = useState<IBanner[]>([]);
  const [mobileBanner, setMobileBanner] = useState<IBanner[]>([]);
  const [metrikaCounted, setMetrikaCounted] = useState(false)
  useEffect(() => {
    fetch(`${baseUrl}gallery_images?category_id=19`)
      .then((res) => res.json())
      .then((data: IBanner[]) => {
        const banner = data.filter((item) => item.id === 88);
        setBanner(banner);
      });

    fetch(`${baseUrl}gallery_images?category_id=19`)
      .then((res) => res.json())
      .then((data: IBanner[]) => {
        const bannerMobile = data.filter((el) => el.id === 89);
        setMobileBanner(bannerMobile);
      });
  }, []);

  const sendBrandbookMetrika = () => {
    if (!metrikaCounted) {
        setMetrikaCounted(true)
        // @ts-ignore
        ym(100105951,'reachGoal','brandbook-download')
    }
  }

  const sendProjectMetrika = () => {
        // @ts-ignore
        ym(100105951,'reachGoal','visit-project')
  }

  return (
    <>
      <Helmet>
        <title>Брендбук НЦПТИ</title>
        <meta
          name="description"
          content="Логотипы, цветовые решения логотипов, значения символов фирменные шрифты и цвета Национального центра информационного противодействия терроризму и экстремизму в образовательной среде и сети Интернет"
        />
        <meta property="og:title" content="Как устроен НЦПТИ" />
        <meta
          property="og:description"
          content="Логотипы, цветовые решения логотипов, значения символов фирменные шрифты и цвета Национального центра информационного противодействия терроризму и экстремизму в образовательной среде и сети Интернет"
        />
        <meta property="og:image" content={Preview} />
        <meta property="og:url" content={href} />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="page-brandbook">
        <h1 className={"main_title"}>Брендбук НЦПТИ</h1>
        {banner &&
          banner.map((i) => (
            <section className="title-image">
              <img
                src={`${baseUrl}${i.file_url}`}
                alt="Баннер Брендбук"
                loading="lazy"
                className="title-image__img"
              />
            </section>
          ))}

        {mobileBanner &&
          mobileBanner.map((e) => (
            <section className="title-image__mobile">
              <img
                src={`${baseUrl}${e.file_url}`}
                alt="Баннер Брендбук мобильный"
                loading="lazy"
                className="title-image__img-mobile"
              />
            </section>
          ))}

        <section className={"page"}>
          {/*Секция со скачивание логотипов*/}
          <section className={"brandbook-logo__section"}>
            <div className={"brandbook-logo__section-left"}>
              <div className={"brandbook-logo__section-left-upper"}>
                <h2>ЛОГОТИП</h2>
                <p>
                  Фирменный знак НЦПТИ представляет собой название центра, а
                  также набор символов, каждый из которых характеризует одно из
                  направлений.
                </p>
              </div>
              <div className={"brandbook-logo__section-left-down"}>
                <h2>ВОЗМОЖНЫЕ ЦВЕТОВЫЕ РЕШЕНИЯ ЛОГОТИПА</h2>
                <p>
                  Логотип НЦПТИ представлен в трёх основных цветовых решениях:
                  цветной, монохромный синий, монохромный белый. Два
                  первоначальных варианта логотипа необходимо размещать на
                  светлом фоне или светных фотографиях с небольшим количеством
                  деталей, последний подохдит для фотографий и тёмного фона
                </p>
              </div>
            </div>
            <aside className={"brandbook-logo__section-right"}>
              <a
                href={"/ncpti-logo-blue.zip"}
                className={"brandbook-logo__section-right-block"}
                download
              >
                <img
                  src={Logo1}
                  alt="синий логотип НЦПТИ"
                  className={"brandbook-logo"}
                />
                <div className={"overlay"}>
                  <a
                    href="/ncpti-logo-blue.zip"
                    download
                    className={"download-btn"}
                  >
                    {" "}
                    Загрузить
                  </a>
                </div>
              </a>
              <a
                href={"/ncpti-logo-light-blue.zip"}
                className={"brandbook-logo__section-right-block"}
                download
              >
                <img
                  src={Logo2}
                  alt="голубой логотип НЦПТИ"
                  className={"brandbook-logo"}
                />
                <div className={"overlay"}>
                  <a
                    href="/ncpti-logo-light-blue.zip"
                    download
                    className={"download-btn"}
                  >
                    {" "}
                    Загрузить
                  </a>
                </div>
              </a>
              <a
                href={"/ncpti-logo-white.zip"}
                className={
                  "brandbook-logo__section-right-block brandbook-logo__section-right-block-blue"
                }
                download
              >
                <img
                  src={Logo3}
                  alt="белый логотип НЦПТИ"
                  className={"brandbook-logo"}
                />
                <div className={"overlay"}>
                  <a
                    href="/ncpti-logo-white.zip"
                    download
                    className={"download-btn"}
                  >
                    {" "}
                    Загрузить
                  </a>
                </div>
              </a>
            </aside>
          </section>

          {/*секция со значением символов*/}
          <section className={"brandbook-symbols"}>
            <h2>Значения символов</h2>
            <p>
              Каждая из фигур логотипа символизирует определённое направление
              работы центра.
              <br />
              Некоторые из них показаны более абстрактно и должны вызвать
              определённые ассоциации.
            </p>
            <p className={"brandbook-symbols__icon"}>
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M40 20C40 23.9556 38.827 27.8224 36.6294 31.1114C34.4318 34.4004 31.3082 36.9638 27.6537 38.4776C23.9991 39.9913 19.9778 40.3874 16.0982 39.6157C12.2186 38.844 8.65491 36.9392 5.85786 34.1421C3.06081 31.3451 1.156 27.7814 0.384294 23.9018C-0.38741 20.0222 0.00865672 16.0009 1.52241 12.3463C3.03616 8.69181 5.59961 5.56824 8.8886 3.37061C12.1776 1.17298 16.0444 -4.71704e-08 20 0V20H40Z"
                  fill="white"
                />
              </svg>
              Статистика, аналитика
            </p>
            <p className={"brandbook-symbols__icon"}>
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M40 0H0V40H40V0ZM14.1176 29.0933L29.8039 20.0369L14.1176 10.9804L14.1176 29.0933Z"
                  fill="white"
                />
              </svg>
              Медиаконтент, работа с интернет-ресурсами
            </p>
            <p className={"brandbook-symbols__icon"}>
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M40 40V26.6667V13.3333V0H26.6667V13.3333H13.3333V26.6667H0V40H13.3333H26.6667H40Z"
                  fill="white"
                />
              </svg>
              Образование, развитие, курсы
            </p>
            <p className={"brandbook-symbols__icon"}>
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 28C24.4183 28 28 24.4183 28 20C28 15.5817 24.4183 12 20 12C15.5817 12 12 15.5817 12 20C12 24.4183 15.5817 28 20 28Z"
                  fill="white"
                />
              </svg>
              Мероприятия (форумы, образовательные интенсивы, фестивали,
              профилактические мероприятия)
            </p>
            <p className={"brandbook-symbols__icon"}>
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 40C0 28.9543 8.95431 20 20 20C31.0457 20 40 28.9543 40 40H0Z"
                  fill="white"
                />
                <path
                  d="M30.4 10C30.4 15.5228 25.9228 20 20.4 20C14.8772 20 10.4 15.5228 10.4 10C10.4 4.47715 14.8772 0 20.4 0C25.9228 0 30.4 4.47715 30.4 10Z"
                  fill="white"
                />
              </svg>
              Работа с молодёжью
            </p>
          </section>

          {/*секция с недопустимыми вариантами использования логотипа*/}
          <section className={"invalid"}>
            <h2>Недопустимые варианты использования логотипа</h2>
            <section className={"invalid-cards"}>
              <div className={"invalid-cards__card"}>
                <img src={Invalid1} alt="фиолетовый логотип" />
                <p>Запрещается изменять цвета</p>
              </div>
              <div className={"invalid-cards__card"}>
                <img src={Invalid2} alt="наклонённый логотип" />
                <p>Запрещается наклонять логотип</p>
              </div>
              <div className={"invalid-cards__card"}>
                <img src={Invalid3} alt="растянутый логотип" />
                <p>Запрещается деформировать логотип</p>
              </div>
              <div className={"invalid-cards__card"}>
                <img src={Invalid4} alt="логотип с тенью" />
                <p>Запрещается добавлять тени и дополнительные эффекты</p>
              </div>
              <div className={"invalid-cards__card"}>
                <img src={Invalid5} alt="логотип на фоне с людьми" />
                <p>Запрещается использовать логотип на сложном фоне</p>
              </div>
              <div className={"invalid-cards__card"}>
                <img src={Invalid6} alt="полупрозрачный логотип" />
                <p>Запрещается воспроизводить логотип с помощью контура</p>
              </div>
            </section>
          </section>

          {/*секция с шрифтами*/}
          <section className={"brandbook-fonts"}>
            <div className={"brandbook-fonts__fonts"}>
              <div className={"brandbook-fonts__fonts-upper"}>
                <h2>Фирменные шрифты</h2>
                <p>
                  Основные шрифты бренда, используемые для набора любых тектов,
                  заголовков и слоганов — Montserrat Alternates и Mulish.
                  <br />
                  <br />
                  Для набора основного тектса рекомендуется применять Mulish, а
                  для заголовков, слоганов, вывесок и т.п. — Montserrat
                  Alternates.
                </p>
              </div>
              <div className={"brandbook-fonts__fonts-down"}>
                <div className={"brandbook-fonts__fonts-down-examples"}>
                  <div
                    className={"brandbook-fonts__fonts-down-examples-Mulish"}
                  >
                    <h3>Аа</h3>
                    <p>Mulish</p>
                    <p>0123456789@!#$%&()</p>
                    <p>абвгдеёжзийклмнопрстуфхцчшщъыьэюя</p>
                    <p>АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯ</p>
                  </div>
                  <div
                    className={
                      "brandbook-fonts__fonts-down-examples-Montserrat"
                    }
                  >
                    <h3>Аа</h3>
                    <p>Montserrat Alternates</p>
                    <p>0123456789@!#$%&()</p>
                    <p>абвгдеёжзийклмнопрстуфхцчшщъыьэюя</p>
                    <p>АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯ</p>
                  </div>
                </div>
                <a href="/fonts_ncpti.zip" download>
                <button className={"brandbook-fonts__fonts-down-btn"}>
                  Скачать
                </button>
                </a>
              </div>
            </div>
            <div className={"brandbook-fonts__colors"}>
              <div>
                <h2>Фирменные цвета</h2>
                <p>
                  В фирменном стиле НЦПТИ используется несколько вариаций синего
                  цвета, а также белый и строгий чёрный
                </p>
              </div>
              <section>
              <div className={"brandbook-fonts__colors-examples-blue"}>
                <p>
                  НЦПТИ фирменный синий
                  <br/>
                  HEX 1934EA
                  <br/>
                  RGB 25 52 234 100%
                  <br/>
                  CMYK 87 76 0 0
                </p>
              </div>
              <div className={"brandbook-fonts__colors-examples"}>
                <div className={"brandbook-fonts__colors-examples-softblue"}>
                  <p>
                    НЦПТИ мягкий синий
                    <br/>
                    HEX 4A5CF8
                    <br/>
                    RGB 74 92 248 100%
                    <br/>
                    CMYK 75 61 0 0
                  </p>
                </div>
                <div className={"brandbook-fonts__colors-examples-lightblue"}>
                  <p>
                    НЦПТИ светло-синий
                    <br/>
                    HEX 6B7FFF
                    <br/>
                    RGB 107 127 255 100%
                    <br/>
                    CMYK 64 48 0 0
                  </p>
                </div>
                <div className={"brandbook-fonts__colors-examples-darkblue"}>
                  <p>
                    НЦПТИ тёмно-синий
                    <br/>
                    HEX 041873
                    <br/>
                    RGB 4 24 115 100%
                    <br/>
                    CMYK 98 98 15 30
                  </p>
                </div>
                <div className={"brandbook-fonts__colors-examples-black"}>
                  <p>
                    НЦПТИ чёрный
                    <br/>
                    HEX 2D2E33
                    <br/>
                    RGB 45 46 51 100%
                    <br/>
                    CMYK 67 61 49 59
                  </p>
                </div>
              </div>
              </section>
            </div>
          </section>

          {/*секция с проектами*/}
          <section className={"brandbook-projects"}>
            <div className={"brandbook-projects__block"}>
              <h2>Наши проекты</h2>
              <div className={"brandbook-projects__block-projects"}>
                <a
                    href={"https://vk.com/podvigrf"}
                  target={"_blank"}
                  className={"brandbook-link"}
                  onClick={sendProjectMetrika}
                >
                  <svg
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="64" height="64" rx="10" fill="#F04D19" />
                    <path
                      d="M48 16L16 25.593V31.1947L48 21.6017V16Z"
                      fill="white"
                    />
                    <path
                      d="M48 24.4026L16 33.9956V39.5974L48 30.0044V24.4026Z"
                      fill="white"
                    />
                    <path
                      d="M48 32.8052L16 42.3982V48L48 38.407V32.8052Z"
                      fill="white"
                    />
                  </svg>
                  Подвиг.РФ
                </a>
                  <a href="https://vk.com/proekt_paralleli" target={'_blank'} onClick={sendProjectMetrika}>
                  <svg
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="64" height="64" rx="10" fill="#DE3435" />
                    <path
                      d="M37.2825 22.0833V43.5577H31.9245V19L37.2825 22.0833Z"
                      fill="#333333"
                    />
                    <path
                      d="M26.5664 22.0833V43.5577H31.9245V19L26.5664 22.0833Z"
                      fill="#FBFAF9"
                    />
                    <path
                      d="M37.2825 22.0833V43.5577H31.9245V19L37.2825 22.0833Z"
                      fill="#333333"
                    />
                    <path
                      d="M26.5664 22.0833V43.5577H31.9245V19L26.5664 22.0833Z"
                      fill="#FBFAF9"
                    />
                    <path
                      d="M26.6172 28.2029V43.5664H21.3088V25.1023L26.6172 28.2029Z"
                      fill="#333333"
                    />
                    <path
                      d="M16.0003 28.2029V43.5664H21.3088V25.1023L16.0003 28.2029Z"
                      fill="#FBFAF9"
                    />
                    <path
                      d="M48 28.2014V43.5577H42.642V25.1022L48 28.2014Z"
                      fill="#333333"
                    />
                    <path
                      d="M37.2839 28.2014V43.5577H42.642V25.1022L37.2839 28.2014Z"
                      fill="#FBFAF9"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M31.9253 19.0001V25.1297L16 34.3242V28.1946L31.9253 19.0001Z"
                      fill="#FBFAF9"
                    />
                    <path
                      d="M26.617 43.5664V28.2029L21.3086 31.2803L21.3086 43.5664H26.617Z"
                      fill="#333333"
                    />
                  </svg>
                  Контент-фабрика «Параллели»
                </a>
                <a href="https://vk.com/otvetyshow" target={'_blank'} onClick={sendProjectMetrika}>
                  <svg
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="64" height="64" rx="10" fill="#131313" />
                    <path
                      d="M47.72 51.6787L43.3615 47.3186C43.117 47.074 42.7866 46.9366 42.4435 46.9366H19.2955C18.5791 46.9366 18 46.3573 18 45.6406V15.296C18 14.5793 18.5791 14 19.2955 14H43.5846C44.301 14 44.8801 14.5793 44.8801 15.296V39.594C44.8801 40.3107 44.301 40.89 43.5846 40.89H25.3442C24.6278 40.89 24.0487 40.3107 24.0487 39.594V21.3469C24.0487 20.6302 24.6278 20.0509 25.3442 20.0509H37.5359C38.2523 20.0509 38.8314 20.6302 38.8314 21.3469V27.4922C38.8314 28.2089 38.2523 28.7882 37.5359 28.7882H34.0826C33.3662 28.7882 32.7871 29.3676 32.7871 30.0842V32.1956C32.7871 32.9123 32.2079 33.4916 31.4915 33.4916H31.3971C30.6807 33.4916 30.1016 32.9123 30.1016 32.1956V27.3935C30.1016 26.6769 30.6807 26.0975 31.3971 26.0975H34.8505C35.5669 26.0975 36.146 25.5182 36.146 24.8015V24.0333C36.146 23.3167 35.5669 22.7373 34.8505 22.7373H28.0339C27.3175 22.7373 26.7384 23.3167 26.7384 24.0333V36.9033C26.7384 37.62 27.3175 38.1993 28.0339 38.1993H40.8991C41.6155 38.1993 42.1947 37.62 42.1947 36.9033V17.9867C42.1947 17.2701 41.6155 16.6907 40.8991 16.6907H21.9853C21.2689 16.6907 20.6897 17.2701 20.6897 17.9867V42.9542C20.6897 43.6709 21.2689 44.2502 21.9853 44.2502H43.5588C43.902 44.2502 44.2323 44.3875 44.4768 44.6322L49.6203 49.7776C50.1265 50.284 50.1265 51.1036 49.6203 51.61L49.5517 51.6787C49.0455 52.1851 48.2262 52.1851 47.72 51.6787Z"
                      fill="#FF9900"
                    />
                    <path
                      d="M31.4919 34.5774H31.3932C30.6777 34.5774 30.0977 35.1576 30.0977 35.8734V35.8777C30.0977 36.5934 30.6777 37.1737 31.3932 37.1737H31.4919C32.2074 37.1737 32.7874 36.5934 32.7874 35.8777V35.8734C32.7874 35.1576 32.2074 34.5774 31.4919 34.5774Z"
                      fill="#131313"
                    />
                  </svg>
                  Шоу «Ответы»
                </a>
              </div>
            </div>
            <div className={"brandbook-all__download"}>
              <h2 className={"brandbook-all__download-title"}>
                Весь брендбук НЦПТИ <br /> в одном месте
              </h2>
              <a href="/NCPTI_brandbook.pdf" download onClick={sendBrandbookMetrika}>
                <button className={"brandbook-all__download-btn"}>Скачать</button>
              </a>
            </div>
          </section>
        </section>
      </div>
    </>
  );
};

export default BrandbookPage;
