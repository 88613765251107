import {IVisitFormat} from "../../../ForumsSettingsPage";
import {IFormState} from "./UpdateForum";

interface IFormAdapted {
    name: string;
    description: string;
    supplement: string;
    forum_location: string;
    image: string;
    is_active: boolean;
    start_date_training: string;
    end_date_training: string;
    forum_visit_format: number[];
    max_seats: number
}

export function formAdapter(formState: IFormState): IFormAdapted {
    const choosenVisitsFormat = [];
    if (formState.forum_visit_format_online) choosenVisitsFormat.push(1);
    if (formState.forum_visit_format_offline) choosenVisitsFormat.push(2);

    const data = {
        name: formState.name,
        description: formState.description,
        supplement: formState.supplement,
        forum_location: formState.forum_location,
        image: formState.image,
        is_active: formState.is_active,
        start_date_training: new Date(formState.start_date_training).toISOString(),
        end_date_training: new Date(formState.end_date_training).toISOString(),
        forum_visit_format: choosenVisitsFormat,
    }

    if (formState.max_seats) {
        //@ts-ignore
        data['max_seats'] = formState.max_seats;
    }

    //@ts-ignore
    data['start_registration_time'] = `${formState['start_registration_time']}Z`;
    //@ts-ignore
    data['end_registration_time'] = `${formState['end_registration_time']}Z`;

    //@ts-ignore
    return data;
}

export function isFormatChoosen(
    formats: IVisitFormat[],
    type: "offline" | "online"
) {
    return !!formats.find((format) => format.name === type);
}

export function getYearMothDayFormat(value: Date | undefined): string {
    if (value) {
        const result =
            value.getFullYear() +
            "-" +
            (value.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            value.getDate().toString().padStart(2, "0");
        return result;
    } else {
        return "";
    }
}

export const getTime = (time: any) => {
    if(!time) return '';
    return `${time.slice(0,2)}:${time.slice(3,5)}`
}
