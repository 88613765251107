import React, { useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import {useAuth} from "../authProvider/authProvider";
import {baseUrl} from "../../constants/constants";
import Modal from "../modal/modal";
import eye from '../../assets/view.svg'
import eyeOff from '../../assets/no-view.svg'
import './modalLogin.scss'


interface Login {
    email?: string;
    password?: string;
}

interface IModalLogin{
    showLoginModal: boolean,
    setShowLoginModal: (state: boolean, isUserAuth?: boolean) => void,
    saveData?: {
        key: string,
        value: string
    }
}


const ModalLogin = ({showLoginModal, setShowLoginModal, saveData}:IModalLogin) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        watch,
    } = useForm<Login>();

    const [icon, setIcon] = useState(eye);
    const [inputType, setInputType] = useState("password");
    const auth = useAuth();

    const nav = useNavigate();

    const closeModal = () => {
        setShowLoginModal(false)
    }

    const togglePassword = () => {
        if (inputType === "password") {
            setInputType("text");
            setIcon(eyeOff);
        } else {
            setInputType("password");
            setIcon(eye);
        }
    };

    const submit: SubmitHandler<Login> = async (data) => {
        try {
            const res = await fetch(`${baseUrl}user/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
            if (res.status === 200) {
                const userResp = await fetch(`${baseUrl}user/me`);
                const userData = await userResp.json();
                auth?.setAuthStatus({
                    isAuth: true,
                    user: userData,
                });
                setShowLoginModal(false, true);
            } else if (res.status === 401) {
                setError("root", { type: "custom", message: "" });
            } else if (res.status === 422) {
                setError("root", { type: "wrong-password", message: "" });
            }
        } catch (err) {
            console.error(err);
        }
    };


    const toReg = () => {
        if(saveData) {
            localStorage.setItem(saveData.key, saveData.value);
        }
        nav("/registration");
    }

    return (
        <Modal
            active={showLoginModal}
            title='Перед подачей заявки необходимо авторизоваться'
            сlose={closeModal}>
            <form onSubmit={handleSubmit(submit)} className="login-form__modal">
                <h1 className="login-title">Войти</h1>
                <div>
                    <input
                        type="email"
                        placeholder="Введите ваш Email"
                        className="login-input"
                        {...register("email", { required: true })}
                    />
                    {errors.email && errors.email.type === "required" && (
                        <p className="wrong-message">Это поле обязательно</p>
                    )}
                </div>
                <div className="input-password">
                    <input
                        type={inputType}
                        placeholder="Введите пароль"
                        className="login-input"
                        {...register("password", { required: true })}
                    />
                              <span
                                  className="password-icon"
                                  onClick={togglePassword}>
                      <img alt="иконка" className="password-icon__img" src={icon} />
                    </span>
                </div>
                {errors.password && errors.password.type === "required" && (
                    <p className="wrong-message">Это поле обязательно</p>
                )}
                {errors.root && (
                    <p className="wrong-message">Почта или пароль введены неправильно</p>
                )}
                <div className="btns">
                    <button className="button login-btn__modal">Войти</button>
                </div>
                <div className="login-support">
                    <a onClick={toReg}  className="login-support__a">
                        Регистрация
                    </a>
                    <Link to={'/forgot-password'} className="login-support__a">
                        Забыли пароль?
                    </Link>
                </div>
            </form>
        </Modal>
    );
};

export default ModalLogin;
