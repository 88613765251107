import React from 'react';
import {IPrivateForumBlanks} from "../../PrivateForumsPage";

type Props = {
} & IPrivateForumBlanks

const BlankForum = (
    {
        id,
        full_name,
        number_phone,
        position_work,
        private_forum_id,
        university,
        gender,
    }: Props) => {

    return (
        <tr>
            <td className='applications-table__tbody-td'>{id}</td>
            <td className='applications-table__tbody-td'>{full_name}</td>
            <td className='applications-table__tbody-td'>{number_phone}</td>
            <td className='applications-table__tbody-td'>{university}</td>
            <td className='applications-table__tbody-td'>{position_work}</td>
            <td className='applications-table__tbody-td'>{gender ? 'Мужской' : 'Женский'}</td>
            <td className='applications-table__tbody-td'>{private_forum_id}</td>
        </tr>
    );
};

export default BlankForum;