import React, {useEffect, useState} from 'react';
import {baseUrl} from "../../../../../../constants/constants";
import {Link} from "react-router-dom";
import {dwnldWriter} from "../../../../../../components/excel-export/excel-export";
import BlankWriter from "./BlankWriter";

export interface IBlankWriter {
    id: number,
    email: string,
    full_name: string,
    number_phone: string,
    title_article: string,
    busyness: string,
    file_article_url: string,
    file_data_user_url: string,
    file_consent_data_processing_url: string,
    file_anti_plagiarism_url: string,
    date_creation: Date,
    theme_article: {
        id: number,
        name: string
    }
}

const WriterTable = () => {
    const [writerBlanks, setWriterBlanks] = useState<IBlankWriter[]>([]);

    useEffect(() => {
        fetch(`${baseUrl}blank_writer?sort_date=true`)
            .then((res) => res.json())
            .then((data) => {
                setWriterBlanks(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);

    return (
        <section className="admin-section">
            <h1 className="admin-title__h1-media">Редактирование секции "Заявки"</h1>
            <section className="admin-media__btns-section">
                <Link to='/admin-panel/applications/forums'>
                    <button className="button admin-media__btn">Форумы</button>
                </Link>
                <Link to='/admin-panel/applications/online'>
                    <button className="button admin-media__btn">Онлайн-курсы</button>
                </Link>
                <Link to='/admin-panel/applications/offline'>
                    <button className="button admin-media__btn">Оффлайн-курсы</button>
                </Link>
                <Link to='#'>
                    <button className="button admin-media__btn-active">Обзор.НЦПТИ</button>
                </Link>
            </section>
            <div className="one-table">
                <div className="admin-buttons">
                    <h3 className='admin-title__h3'>Обзор.НЦПТИ</h3>
                    <button className="button admin-btn" onClick={dwnldWriter}>Excel</button>
                </div>
                <table
                    className='applications-table'
                >
                    <thead className='applications-table__thead'>
                    <tr>
                        <th className='applications-table__thead-th'>Выпуск, на который направлена статья</th>
                        <th className='applications-table__thead-th'>Имя</th>
                        <th className='applications-table__thead-th'>Email</th>
                        <th className='applications-table__thead-th'>Телефон</th>
                        <th className='applications-table__thead-th'>Место работы</th>
                        <th className='applications-table__thead-th'>Название статьи</th>
                        <th className='applications-table__thead-th'>Файл статьи</th>
                        <th className='applications-table__thead-th'>Файл антиплагиата</th>
                        <th className='applications-table__thead-th'>Файл с данными о пользователе</th>
                        <th className='applications-table__thead-th'>Файл с согласием на обработку ПДН</th>
                        <th className='applications-table__thead-th'>Дата подачи</th>
                    </tr>
                    </thead>
                    {writerBlanks.map((item) =>
                        <tbody className='applications-table__tbody' key={item.id}>
                        <BlankWriter {...item}/>
                        </tbody>
                    )}
                </table>
            </div>

        </section>
    );
};

export default WriterTable;