import React from 'react';
import {useEffect, useState} from "react";
import {useAuth} from "../../../../components/authProvider/authProvider";
import {baseUrl} from "../../../../constants/constants";
import {SubmitHandler, useForm} from "react-hook-form";
import {successToast} from "../../../../shared/toastify";

interface IOfflineCourseForm{
    offline_course_id: number,
    contact_email: string,
    contact_full_name: string,
    contact_number_phone: string,
    event_location: string,
    topics_discussion: string,
    desired_time: Date
}

interface Props {
    close: () => void
}

const EducationModal = ({close}: Props) => {
    const isAuth = useAuth()
    const [metrikaCounted, setMetrikaCounted] = React.useState(false);

    const {register,
        handleSubmit,
        formState:{errors},
        setError
    } = useForm<IOfflineCourseForm>()

    const sendMetrika = () => {
        if (metrikaCounted) {
          return
        }
        // @ts-ignore
        ym(100105951, "reachGoal", "education-offline");
        setMetrikaCounted(true)
      };

    const submit: SubmitHandler<IOfflineCourseForm> = async(data)=>{
        try{
            const res = await fetch(`${baseUrl}blank_offline_course`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if (res.status === 200){
                sendMetrika()
                localStorage.setItem('form', 'accepted');
                successToast('Форма успешно отправлена');
                close()
            } else if(res.status === 401) {
                setError('root', {type: 'custom', message: ''})
            }
        } catch (err){
            console.error(err)
        }
        }

    const [values, setValues] = useState('0')

    return (
        <div>
            <form
                onSubmit={handleSubmit(submit)}
                className='regForm'
            >
                <label className='regLabel'>
                    Выберите, какое мероприятие хотите провести для специалистов:
                    <select
                        id="form-of-participation"
                        value={values}
                        {...register('offline_course_id', {required: true})}
                        onChange={(e) => setValues(e.target.value)}
                    >
                        <option value='1'>программу ДПО</option>
                        <option value='2'>учебно-методический семинар</option>
                    </select>
                </label>
                <input
                    type="text"
                    className='regInput'
                    placeholder='Напишите, какие темы вы бы хотели обсудить в рамках мероприятия'
                    {...register('topics_discussion', {required: true})}
                />
                {errors.topics_discussion && <p className='wrong-message'>Это поле обязательно</p>}
                <input
                    type="text"
                    className='regInput'
                    placeholder='Где вы хотите провести мероприятие'
                    {...register('event_location', {required: true})}
                />
                {errors.event_location && <p className='wrong-message'>Это поле обязательно</p>}
                <input
                    type="date"
                    className='regInput'
                    placeholder='Когда вы хотите провести мероприятие'
                    {...register('desired_time', {required: true})}
                />
                {errors.desired_time && <p className='wrong-message'>Это поле обязательно</p>}
                <input
                    type="text"
                    className='regInput'
                    placeholder='ФИО контактного лица'
                    defaultValue={isAuth?.authState.user?.full_name}
                    {...register('contact_full_name', {required: true})}
                />
                {errors.contact_full_name && <p className='wrong-message'>Это поле обязательно</p>}
                <input
                    type="text"
                    className='regInput'
                    placeholder='Номер телефона контактного лица'
                    defaultValue={isAuth?.authState.user?.number_phone == null ? '+7' : `+${isAuth?.authState.user?.number_phone}`}
                    {...register('contact_number_phone',
                        {required: true,
                            validate: {validatePhone: (v) => /\+7\d{3}\d{3}\d{2}\d{2}/.test(v) || 'Введите номер телефона, начиная с +7'},
                        })
                }
                />
                {errors.contact_number_phone && <p className='wrong-message'>Это поле обязательно</p>}
                <input
                    type="text"
                    className='regInput'
                    placeholder='Почта контактного лица'
                    {...register('contact_email', {required: true})}
                />
                {errors.contact_email && <p className='wrong-message'>Это поле обязательно</p>}
                <button className="button form-btn">Отправить</button>
            </form>
        </div>
    );
};

export default EducationModal;