import React, {useState} from 'react';
import {IDepartmentsSettings} from "../../../AboutUsSettingsPage";
import {baseUrl} from "../../../../../../../constants/constants";
import Modal from "../../../../../../../components/modal/modal";
import UpdateCard from "../../../../MediaSettingsPage/components/Cards/UpdateCard/UpdateCard";
import UpdateDepartment from "../UpdateDepartment/UpdateDepartment";

interface IDepartmentList{
    departmentList: IDepartmentsSettings;
    deleteDepartment: (currentDepartment: IDepartmentsSettings) => void
}

const DepartmentList:React.FC<IDepartmentList> = ({departmentList, deleteDepartment}) => {
    const [showUpdateDeptModal, setShowUpdateDeptModal] = useState(false)

    const closeModal = () => {
        setShowUpdateDeptModal(false)
    }

    return (
        <section className='admin-settings__section'>
            <img
                src={`${baseUrl}${departmentList.image_url}`}
                alt="Картинка отдела"
                className="admin-settings__img-department"
            />
            <h2 className="admin-settings__h2">Название: {departmentList.name}</h2>
            <p className="admin-settings__p">Приоритет: {departmentList.priority}</p>
            <div className="settings-buttons">
            <button
                className="button updateBtn"
                onClick={() => setShowUpdateDeptModal(true)}
            >
                Обновить
            </button>
            {showUpdateDeptModal &&
                <Modal
                    active={showUpdateDeptModal}
                    title='Обновите информацию'
                    сlose={closeModal}
                >
                    <UpdateDepartment
                        department={departmentList}
                        close={closeModal}
                    />
                </Modal>
            }
            <button
                className="button deleteBtn"
                onClick={() => deleteDepartment(departmentList)}
            >
                Удалить
            </button>
            </div>
        </section>
    );
};

export default DepartmentList;