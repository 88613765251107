'use client'
import React, { useState } from 'react';
import {VideoInterface} from "../video";

const OneVideo = ({id, name, video_url, create_date, category_id, category_name}: VideoInterface) => {
    const [viewed, setViewed] = useState(false)
    const handleView = () => {
        if (!viewed) {
            setViewed(true)
            if (category_id === 12) {
                // @ts-ignore
                ym(100105951,'reachGoal','video-student')
            } else if (category_id === 18) {
                // @ts-ignore
                ym(100105951,'reachGoal','video-profi')
            } else if (category_id === 13) {
                // @ts-ignore
                ym(100105951,'reachGoal','video-parallels')
            }
        }
    }
    return (
            <div className='video-section' onClick={handleView}>
                <iframe
                    className='video'
                    src={video_url}
                    allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameBorder="0"
                    allowFullScreen>
                </iframe>
                <p className='video-text'>{name}</p>
            </div>
    );
};

export default OneVideo;