import React from 'react';
import {SborniksInterface} from "../sborniks";
import {baseUrl} from "../../../../../../../constants/constants";

const Sbornik = ({id, name, file_url, file_card_url, type_file, file_extension, create_date, category_id, category_name}: SborniksInterface) => {
    const [metrikaCounted, setMetrikaCounted] = React.useState(false)
    const handleView = () => {
        if (!metrikaCounted) {
            setMetrikaCounted(true)
            // @ts-ignore
            ym(100105951,'reachGoal','metodichki-sbornik')
        }
    }
    return (
        <>
            <div className="one-sbornik">
                <a href={`${baseUrl}${file_url}`} onClick={handleView} target='_blank'>
                    <img src={`${baseUrl}${file_card_url}`} alt="Обложка методических рекомендаций" className='manuals-image'/>
                    <p className='manuals-description'>{name}</p>
                </a>
            </div>
        </>
    );
};

export default Sbornik;