import React, {useEffect, useState} from 'react';
import './anonim-form.scss'
import {SubmitHandler, useForm} from "react-hook-form";
import {useNavigate, useSearchParams} from "react-router-dom";
import {baseUrl} from "../../constants/constants";
import {errorToast, successToast} from "../toastify";

export function checkErrorText(inputName: string, errors: any) {
    return errors?.[inputName] && errors?.[inputName]?.message;
}

interface IAnonimForm{
    full_name: string,
    number_phone: string;
    position_work: string,
    gender: boolean,
    token: string,
    is_privacy_policy_accepted: boolean,
    is_data_processing_consent_given: boolean,
    university: string
}

interface IPForum{
    name: string
}

const AnonimForm = () => {
    const{register,
        handleSubmit,
        formState:{errors},
        setError
    } = useForm<IAnonimForm>()
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get('token');
    const [pForum, setPForum] = useState<IPForum[]>([])

    useEffect(() => {
        const checkToken = async () => {
            if (!token) {
                navigate('/');
                return;
            }

            try {
                const response = await fetch(`${baseUrl}private_forum/check_forum/${token}`);
                const data = await response.json(); // Парсим JSON из ответа

                if (data.message === "false") {
                    navigate('/'); // Редирект на главную
                }
            } catch (error) {
                console.error('Ошибка при проверке токена:', error);
                navigate('/');
            }
        };

        checkToken();
    }, [token, navigate]);

    const submit: SubmitHandler<IAnonimForm> = async(data) => {
        try{
            const res = await fetch(`${baseUrl}private_blank_forum`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({...data, token})
            });
            if(res.status === 200){
                successToast('Заявка подана');
                setTimeout(() => {navigate('/')},3000,)

            }
            else if (res.status === 401){
                errorToast('Ошибка');
                setError('root', {type: 'custom', message: ''})
            }
        } catch (err){
            console.log(err)
        }
    }

    // useEffect(() => {
    //     fetch(`${baseUrl}private_forum`)
    //         .then((res) => res.json())
    //         .then((data) => {
    //             setPForum(data)
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }, []);

    return (
        <section className={'anonim-form'}>
        <form
            className={"regForm anonim-form__form"}
            onSubmit={handleSubmit(submit)}
        >
            {/*{pForum.map((i) => (*/}
            {/*    <h1 className={'anonim-form__title'}>*/}
            {/*        {i.name}*/}
            {/*    </h1>*/}
            {/*))}*/}

            <h1 className={'anonim-form__title'}>
                Комплексная безопасность в образовательной организации высшего образования
            </h1>

            <input
                type="text"
                className='regInput'
                placeholder={'ФИО'}
                {...register('full_name', {required: true})}
            />
            {errors.full_name && <p className='wrong-message'>Это поле обязательно</p>}

            <input
                type="text"
                className={'regInput'}
                placeholder={'Телефон'}
                {...register('number_phone', {
                    required: true,
                    validate: {validatePhone: (v) => /\+7\d{3}\d{3}\d{2}\d{2}/.test(v) || 'Введите номер телефона, начиная с +7'}
                })}
            />
            {errors.number_phone && <p className="wrong-message">{checkErrorText('number_phone', errors)}</p>}
            {errors.number_phone && <p className='wrong-message'>Это поле обязательно</p>}

            <input
                type="text"
                className={'regInput'}
                placeholder={'ВУЗ'}
                {...register('university', {required: true})}
            />
            {errors.university && <p className='wrong-message'>Это поле обязательно</p>}

            <input
                type="text"
                className={'regInput'}
                placeholder={'Должность'}
                {...register('position_work', {required: true})}
            />
            {errors.position_work && <p className='wrong-message'>Это поле обязательно</p>}

            <label
                className='account-label'
            >
                Ваш пол
                <span>
                    <input
                        type="radio"
                        className='account-label__radio'
                        value='true'
                        {...register('gender')}
                    />
                        <label className='account-label__radio__label'>Мужской</label>
                        </span>
                <span>
                    <input
                        type="radio"
                        className='account-label__radio'
                        value='false'
                        {...register('gender')}/>
                        <label className='account-label__radio__label'>Женский</label>
                        </span>
            </label>
            <div className="register-approval">
                    <span
                        className='regInput__checkbox'
                        style={{margin: '.5rem 0'}}
                    >
                        <input type="checkbox" className='regInput__checkbox-galka' required {...register('is_privacy_policy_accepted')}/>
                    <label>
                        <a
                            href="/soglasie.doc"
                            download
                            className='register-approval__link'
                        >Согласие </a>на обработку персональных данных
                    </label>
                </span>
                <span className='regInput__checkbox'>
                    <input type="checkbox" className='regInput__checkbox-galka' required {...register('is_data_processing_consent_given')}/>
                    <label>
                        Ознакомлен с <a href="/politika-konfidenczialnosti.docx" download
                                        className='register-approval__link'>
                            политикой конфиденциальности
                        </a>
                    </label>
                </span>
            </div>
            <button className={'button'}>Подать заявку</button>
        </form>
        </section>
    );
};

export default AnonimForm;