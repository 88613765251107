import React, { useEffect, useState } from "react";
import "./parallels.scss";
import { Link } from "react-router-dom";
import { baseUrl } from "../../../../../constants/constants";
import OneVideo from "../components/oneVideo";
import ParallelsOneVideo from "../components/parallelsOneVideo";
import Preview from "../../../../../assets/materails_cards/video-mobile_.webp";
import { Helmet } from "react-helmet-async";
const href =
  typeof window !== "undefined" ? window.location.href : "https://нцпти.рф/";

export interface ParallelsVideoInterface {
  id: number;
  name: string;
  video_url: string;
  create_date: Date;
  category_id: number;
  category_name: string;
}

interface IBanner {
  id: number;
  name: string;
  file_url: string;
}
const Parallels = () => {
  const [video, setVideo] = useState<ParallelsVideoInterface[]>([]);
  const [banner, setBanner] = useState<IBanner[]>([]);
  const [mobileBanner, setMobileBanner] = useState<IBanner[]>([]);

  useEffect(() => {
    fetch(`${baseUrl}gallery_images?category_id=19`)
      .then((res) => res.json())
      .then((data: IBanner[]) => {
        const banner = data.filter((item) => item.id === 68);
        setBanner(banner);
        console.log(banner);
      });

    fetch(`${baseUrl}gallery_images?category_id=19`)
      .then((res) => res.json())
      .then((data: IBanner[]) => {
        const bannerMobile = data.filter((el) => el.id === 78);
        setMobileBanner(bannerMobile);
      });
  }, []);

  useEffect(() => {
    fetch(`${baseUrl}gallery_video?sort_date=true&category_id=13`)
      .then((res) => res.json())
      .then((data) => {
        setVideo(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Фестиваль "Параллели" | НЦПТИ</title>
        <meta
          name="description"
          content='Работы фестиваля "Параллели", проводимого Национальным центром информационного противодействия терроризму и экстремизму в образовательной среде и сети Интернет'
        />
        <meta property="og:title" content='Фестиваль "Параллели" | НЦПТИ' />
        <meta
          property="og:description"
          content='Работы фестиваля "Параллели", проводимого Национальным центром информационного противодействия терроризму и экстремизму в образовательной среде и сети Интернет'
        />
        <meta property="og:image" content={Preview} />
        <meta property="og:url" content={href} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="page-video">
        <h1 className="main_title">Работы фестиваля "Параллели"</h1>
        {banner &&
          banner.map((i) => (
            <section className="title-image">
              <img
                src={`${baseUrl}${i.file_url}`}
                alt="Баннер Видео"
                loading="lazy"
                className="title-image__img"
              />
            </section>
          ))}

        {mobileBanner &&
          mobileBanner.map((e) => (
            <section className="title-image__mobile">
              <img
                src={`${baseUrl}${e.file_url}`}
                alt="Баннер Видео мобильный"
                loading="lazy"
                className="title-image__img-mobile"
              />
            </section>
          ))}
        <div className="page">
          <div className="page-btns">
            <Link to="/materialy/videomaterialy">
              <button className="button video-btn">Ролики</button>
            </Link>
            <Link to="#">
              <button className="button video-btn__active">
                Работы Контент-фабрики «Параллели»
              </button>
            </Link>
          </div>

          <h2 className="title-h2 video-containers__title">
            Работы Контент-фабрики «Параллели»
          </h2>
          <section className="video-containers">
            {video && video.length > 0 ? (
              video.map((item) => <ParallelsOneVideo key={item.id} {...item} />)
            ) : (
              <h2 className="notFound-title">Видео не найдены</h2>
            )}
          </section>
          <a href="https://proekt-paralleli.ru/" target="_blank">
            <button className="button save-video__btn">Перейти на страницу проекта</button>
          </a>
        </div>
      </div>
    </>
  );
};

export default Parallels;
