import React from "react";
import "./onlineCourses.scss";
import ImageTitle from "../../../assets/title_cards/online_.webp";
import { useState, useEffect } from "react";
import { baseUrl } from "../../../constants/constants";
import Preview from "../../../assets/cards/courses_.webp";
import Course from "./components/course/course";
import { Helmet } from "react-helmet-async";
const href =
  typeof window !== "undefined" ? window.location.href : "https://нцпти.рф/";

export interface CourseInterface {
  id: number;
  name: string;
  description: string;
  price: number;
  image_url: string;
  is_active: boolean;
}

interface IBanner {
  id: number;
  name: string;
  file_url: string;
}

const OnlineCourses = () => {
  const [courses, setCourses] = useState<CourseInterface[]>([]);
  const [banner, setBanner] = useState<IBanner[]>([]);
  const [mobileBanner, setMobileBanner] = useState<IBanner[]>([]);

  useEffect(() => {
    fetch(`${baseUrl}gallery_images?category_id=19`)
      .then((res) => res.json())
      .then((data: IBanner[]) => {
        const banner = data.filter((item) => item.id === 73);
        setBanner(banner);
        console.log(banner);
      });

    fetch(`${baseUrl}gallery_images?category_id=19`)
      .then((res) => res.json())
      .then((data: IBanner[]) => {
        const bannerMobile = data.filter((el) => el.id === 82);
        setMobileBanner(bannerMobile);
      });
  }, []);

  useEffect(() => {
    fetch(`${baseUrl}online_course?sort_date=true`)
      .then((res) => res.json())
      .then((data) => {
        setCourses(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Онлайн-курсы | НЦПТИ</title>
        <meta
          name="description"
          content="Бесплатные программы повышения квалификации в удобном формате с опытным специалистов, выдача сертификата (платно)"
        />
        <meta property="og:title" content="Онлайн-курсы | НЦПТИ" />
        <meta
          property="og:description"
          content="Бесплатные программы повышения квалификации в удобном формате с опытным специалистов, выдача сертификата (платно)"
        />
        <meta property="og:image" content={Preview} />
        <meta property="og:url" content={href} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="page-online">
        <h1 className="main_title">Онлайн-курсы</h1>
        {banner &&
          banner.map((i) => (
            <section className="title-image">
              <img
                src={`${baseUrl}${i.file_url}`}
                alt="Баннер Онлайн курсы"
                loading="lazy"
                className="title-image__img"
              />
            </section>
          ))}

        {mobileBanner &&
          mobileBanner.map((e) => (
            <section className="title-image__mobile">
              <img
                src={`${baseUrl}${e.file_url}`}
                alt="Баннер Онлайн курсы мобильный"
                loading="lazy"
                className="title-image__img-mobile"
              />
            </section>
          ))}

        <div className="page">
          <section className="allCourses">
            {courses && courses.length > 0 ? (
              courses.map((item) => <Course key={item.id} {...item} />)
            ) : (
              <h2 className="notFound-title">Курсы не найдены</h2>
            )}
          </section>
        </div>
      </div>
    </>
  );
};

export default OnlineCourses;
