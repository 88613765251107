import React from 'react';
import './applicationsPage.scss'
import {Link} from "react-router-dom";



const ApplicationsPage = () => {
    return (
        <>
            <h1 className="admin-title__h1">Редактирование секции "Заявки"</h1>
            <section className="admin-media__btns-section">
                <Link to='forums'>
                    <button className="button admin-media__btn">Форумы</button>
                </Link>
                <Link to='online'>
                    <button className="button admin-media__btn">Онлайн-курсы</button>
                </Link>
                <Link to='offline'>
                    <button className="button admin-media__btn">Оффлайн-курсы</button>
                </Link>
                <Link to='obzor'>
                    <button className="button admin-media__btn">Обзор.НЦПТИ</button>
                </Link>
            </section>
        </>
    );
};

export default ApplicationsPage;