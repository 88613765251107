import React, {useState} from 'react';
import {CourseInterface} from "../../onlineCourses";
import Modal from "../../../../../components/modal/modal";
import CourseRegistrationForm from "../coursesRegistrationForm/courseRegistrationForm";
import {baseUrl} from "../../../../../constants/constants";
import {useAuth} from "../../../../../components/authProvider/authProvider";
import ModalLogin from "../../../../../components/modalLogin/modalLogin";

const Course:React.FC<CourseInterface> = ({name, description, id, image_url, price}) => {
    const[showModal, setShowModal] = useState(false)
    const[showLoginModal, setShowLoginModal] = useState(false)
    const isAuth = useAuth()
    const openPopupWithAuth = () => {
        if (isAuth?.authState?.isAuth){
            setShowModal(true)
        } else {
            setShowLoginModal(true)
        }
    }

    const closeModal = () => {
        setShowModal(false)
    }


    return (
        <>
            <section className="course">
                <div className="course-img">
                    <img src={`${baseUrl}${image_url}`} alt="Карточка" loading='lazy' className='course-img__img'/>
                </div>

                <div className="course-description">
                    <div className="course-description__text">
                        <h3 className="course-title">{name}</h3>
                        <p className="course-desc">{description}</p>
                    </div>
                    <div className="course-description__price">
                        <div className="course-description__price-text">
                            <h2 className="course-price">БЕСПЛАТНО <span className={price === 0 ? 'hide-element' : 'course-price__number'}> / {price} рублей*</span></h2>
                            <p className={price === 0 ? 'hide-element' : 'course-price__description'}>*стоимость получения удостоверения о повышении
                                квалификации</p>
                        </div>
                    <button
                        className="button course-btn"
                        onClick={() => openPopupWithAuth()}
                    >
                        Подать заявку
                    </button>

                {showModal && <Modal
                        active={showModal}
                        title={'Оставьте свои данные, наши специалисты свяжутся с Вами для уточнения информации'}
                        сlose={closeModal}
                >
                    <CourseRegistrationForm
                        online_course_id={id}
                        close={closeModal}
                    />
                </Modal>
                    }
                {showLoginModal &&
                <ModalLogin
                    showLoginModal={showLoginModal}
                    setShowLoginModal={setShowLoginModal}
                />
                }
                    </div>
                </div>
            </section>
        </>
    );
};

export default Course;