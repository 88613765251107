import React, { useEffect, useState } from "react";
import "./aboutUs.scss";
import GroupPhoto from "../../../assets/about-ncpti/Group_.webp";
import Churilov from "../../../assets/about-ncpti/Churilov_Sergey_.webp";
import Chunin from "../../../assets/about-ncpti/Chunin_Alex_.webp";
import Byikadorova_Alex from "../../../assets/about-ncpti/Byikadorova_Alex.jpg";
import Preview from "../../../assets/cards/team_.webp";
import { Link } from "react-router-dom";
import { baseUrl } from "../../../constants/constants";
import { Helmet } from "react-helmet-async";
const href =
  typeof window !== "undefined" ? window.location.href : "https://нцпти.рф/";

export interface IDepartments {
  id: number;
  name: string;
  image_url: string;
  banner_desktop_url: string;
  banner_mobile_url: string;
}

interface IBanner {
  id: number;
  name: string;
  file_url: string;
}

const AboutUs = () => {
  const [departments, setDepartment] = useState<IDepartments[]>([]);
  const [banner, setBanner] = useState<IBanner[]>([]);
  const [mobileBanner, setMobileBanner] = useState<IBanner[]>([]);

  useEffect(() => {
    fetch(`${baseUrl}gallery_images?category_id=19`)
      .then((res) => res.json())
      .then((data: IBanner[]) => {
        const banner = data.filter((item) => item.id === 75);
        setBanner(banner);
      });

    fetch(`${baseUrl}gallery_images?category_id=19`)
      .then((res) => res.json())
      .then((data: IBanner[]) => {
        const bannerMobile = data.filter((el) => el.id === 76);
        setMobileBanner(bannerMobile);
      });
  }, []);

  useEffect(() => {
    fetch(`${baseUrl}departament`)
      .then((res) => res.json())
      .then((data: IDepartments[]) => {
        setDepartment(
          data.filter((department) => department.id != 4 && department.id != 1)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Как устроен НЦПТИ</title>
        <meta
          name="description"
          content="Структура, руководство и команда Национального центра информационного противодействия терроризму и экстремизму в образовательной среде и сети Интернет"
        />
        <meta property="og:title" content="Как устроен НЦПТИ" />
        <meta
          property="og:description"
          content="Структура, руководство и команда Национального центра информационного противодействия терроризму и экстремизму в образовательной среде и сети Интернет"
        />
        <meta property="og:image" content={Preview} />
        <meta property="og:url" content={href} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="page-team">
        <h1 className={"main_title"}>Как устроен НЦПТИ</h1>
        {banner &&
          banner.map((i) => (
            <section className="title-image">
              <img
                src={`${baseUrl}${i.file_url}`}
                alt="Баннер Наша команда"
                loading="lazy"
                className="title-image__img"
              />
            </section>
          ))}

        {mobileBanner &&
          mobileBanner.map((e) => (
            <section className="title-image__mobile">
              <img
                src={`${baseUrl}${e.file_url}`}
                alt="Баннер Наша команда мобильный"
                loading="lazy"
                className="title-image__img-mobile"
              />
            </section>
          ))}

        <section className="page">
          <div className="team-title">
            <img
              className="team-title__img-main"
              src={GroupPhoto}
              alt="Групповое фото НЦПТИ"
              loading="lazy"
              style={{ objectPosition: "center" }}
            />
          </div>
          <h2 className="title-h2 team-links__h">Руководители</h2>
          <section className="directors-section" style={{ marginBottom: "0" }}>
            <div className="directors-card">
              <div className="directors-card__img">
                <img
                  src={Churilov}
                  alt="Чурилов Сергей Анатольевич"
                  className="directors-card__img-img"
                  loading="lazy"
                />
              </div>
              <div className="directors-card__text">
                <h3 className="directors-card__text-h">
                  Чурилов Сергей Анатольевич
                </h3>
                <p className="directors-card__text-p">Директор НЦПТИ</p>
              </div>
            </div>
            <div className="directors-card">
              <div className="directors-card__img">
                <img
                  src={Chunin}
                  alt="Чунин Александр Сергеевич"
                  className="directors-card__img-img"
                  loading="lazy"
                />
              </div>
              <div className="directors-card__text">
                <h3 className="directors-card__text-h">
                  Чунин Александр Сергеевич
                </h3>
                <p className="directors-card__text-p">
                  Заместитель директора НЦПТИ
                </p>
              </div>
            </div>
          </section>
          {/*<section className="directors-section" style={{marginTop:'1rem'}}>*/}
          {/*    <div className="directors-card">*/}
          {/*        <div className='directors-card__img'>*/}
          {/*            <img*/}
          {/*                src={Byikadorova_Alex}*/}
          {/*                alt="Быкадорова Александра Сергеевна"*/}
          {/*                className='directors-card__img-img'*/}
          {/*                loading='lazy'*/}
          {/*            />*/}
          {/*        </div>*/}
          {/*        <div className="directors-card__text">*/}
          {/*            <h3 className='directors-card__text-h'>Быкадорова Александра Сергеевна</h3>*/}
          {/*            <p className='directors-card__text-p'>Заместитель директора НЦПТИ</p>*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*    <div className="directors-card" style={{background:'#fff'}}>*/}
          {/*        /!*<div className='directors-card__img'>*!/*/}
          {/*        /!*    <img*!/*/}
          {/*        /!*        src={Chunin}*!/*/}
          {/*        /!*        alt="Чунин Александр Сергеевич"*!/*/}
          {/*        /!*        className='directors-card__img-img'*!/*/}
          {/*        /!*        loading='lazy'*!/*/}
          {/*        /!*    />*!/*/}
          {/*        /!*</div>*!/*/}
          {/*        /!*<div className="directors-card__text">*!/*/}
          {/*        /!*    <h3 className='directors-card__text-h'>Чунин Александр Сергеевич</h3>*!/*/}
          {/*        /!*    <p className='directors-card__text-p'>Заместитель директора НЦПТИ</p>*!/*/}
          {/*        /!*</div>*!/*/}
          {/*    </div>*/}
          {/*</section>*/}
          <h2 className="title-h2 team-links__h">Наши отделы</h2>
          <section className="team-links">
            {departments.map((department) => {
              return (
                <section className="team-links__card" key={department.id}>
                  <Link to={`department/${department.id}`}>
                    <div className="team-links__card-img-block">
                      <img
                        src={baseUrl + department.image_url}
                        alt="Групповая фотография отдела"
                        className="team-links__card-img"
                        loading="lazy"
                      />
                    </div>
                    <div className="team-links__card-text-block">
                      <h3>{department.name}</h3>
                    </div>
                  </Link>
                </section>
              );
            })}
          </section>
        </section>
      </div>
    </>
  );
};

export default AboutUs;
