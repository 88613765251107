import React, {useEffect, useState} from 'react';
import './forumsRegistrationForm.scss'
import {SubmitHandler, useForm} from "react-hook-form";
import {baseUrl} from "../../../../../constants/constants";
import {AccountInterface} from "../../../../User/AccountPage/account";
import {useAuth} from "../../../../../components/authProvider/authProvider";
import {errorToast, successToast} from "../../../../../shared/toastify";
import axios, {AxiosError} from "axios";
import {toast} from "react-toastify";

export function checkErrorText(inputName: string, errors: any) {
    return errors?.[inputName] && errors?.[inputName]?.message;
}

interface Form {
    id: number,
    email: string,
    full_name: string,
    number_phone: string,
    region_residence: string,
    place_of_work: string,
    position_work: string,
    travel_certificate: boolean,
    is_mailing: boolean
    visit_format_id: number,
    date_creation: Date,
    questions: string,
    educational_organization_id: number | null
}

interface Props {
    id: number,
    close: () => void,
    forum_visit_format: any[],
    educational_organization_id: any[],
}

const copyOnlyLive = (object: any) => {
    const copy = {};

    Object.keys(object).forEach((key: string) => {
        if (object[key] === null || object[key] === undefined) {
            return;
        }
        //@ts-ignore
        copy[key] = object[key];
    })

    return copy;
}


const RegistrationForm = ({id, close, forum_visit_format, educational_organization_id}: Props) => {
    const isAuth = useAuth()
    const [metrikaCounted, setMetrikaCounted] = React.useState(false)
    const handleRegister = () => {
        if (!metrikaCounted) {
            setMetrikaCounted(true)
            // @ts-ignore
            ym(100105951,'reachGoal','forums-register')
        }
    }

    const {
        register,
        handleSubmit,
        formState: {errors},
        setError,
        watch,
        setValue,
    } = useForm<Form>({
        defaultValues: {
            is_mailing: true
        }
    });

    const [orgList, setOrgList] = useState<Array<{ id: number, name: string }>>([]);

    useEffect(() => {
        axios.get(`${baseUrl}educational_organization`)
            .then((res) => res.data)
            .then(d => {
                setOrgList(d);
                setValue('educational_organization_id', null)
            })
    }, []);

    const {visit_format_id} = watch();

    useEffect(() => {
        if (forum_visit_format.find(f => f.id == visit_format_id)?.name === 'online') {
            setValue('educational_organization_id', null)
        } else {
            setValue('educational_organization_id', 1)
        }
    }, [visit_format_id, forum_visit_format])

    const submit: SubmitHandler<Form> = async (data) => {
        toast.promise(
            new Promise((res, rej) => {
                axios.post(`${baseUrl}blank_forum`, {
                    ...copyOnlyLive(data),
                    forum_id: id
                })
                    .then((e) => {
                        res('ok');
                        handleRegister()
                        localStorage.setItem('form', 'accepted');
                    })
                    .catch((e) => {
                        const ae = e as AxiosError

                        if (ae.status === 401) {
                            setError('root', {type: 'custom', message: ''});
                            rej('error')
                            return;
                        }
                        if (ae.status === 409) {
                            setError('root', {type: 'repeatRequest', message: 'Вы отправили повторную заявку'})
                            rej('error')
                            return;
                        }
                    })
            }),
            {
                'pending': 'Отправление заявки на регистрацию',
                'success': 'Заявка успешно отправлена',
                'error': 'Вы ранее направляли заявку на форум. Для редактирования заявки обратитесь к администраторам сайта по электронной почте info@ncpti.ru или по номеру телефона +7(863)201-28-22'
            },
            {
                autoClose: false
            }
        ).then(() => {
            setTimeout(() => {
                //close();
            }, 1000)
        }).catch(() => {

        })
    }

    return (
        <form
            onSubmit={handleSubmit(submit)}
            className='regForm'
        >
            <input
                type="text"
                placeholder='ФИО'
                className='regInput'
                {...register('full_name',
                    {required: true, value: isAuth?.authState.user?.full_name})}/>
            {errors.full_name && <p className='wrong-message'>Это поле обязательно</p>}
            <input
                type="text"
                placeholder='Субъект России'
                className='regInput'
                {...register('region_residence',
                    {required: true,})}/>
            {errors.region_residence && <p className='wrong-message'>Это поле обязательно</p>}
            <input
                type="text"
                placeholder='Место работы'
                className='regInput'
                {...register('place_of_work',
                    {
                        required: true,
                        value: isAuth?.authState.user?.place_of_work
                    })}/>
            {errors.position_work && <p className='wrong-message'>Это поле обязательно</p>}
            <input
                type="text"
                placeholder='Должность'
                className='regInput'
                {...register('position_work',
                    {
                        required: true,
                        value: isAuth?.authState.user?.position_work
                    })}/>
            {errors.place_of_work && <p className='wrong-message'>Это поле обязательно</p>}
            <input
                type="text"
                placeholder='Контактный номер телефона'
                className='regInput'
                defaultValue={isAuth?.authState.user?.number_phone == null ? '+7' : `+${isAuth?.authState.user?.number_phone}`}
                {...register('number_phone',
                    {
                        required: true,
                        validate: {validatePhone: (v) => /\+7\d{3}\d{3}\d{2}\d{2}/.test(v) || 'Введите номер телефона, начиная с +7'}
                    })}
            />
            {errors.number_phone && <p className='wrong-message'>{checkErrorText('number_phone', errors)}</p>}
            {errors.number_phone && <p className='wrong-message'>Это поле обязательно</p>}
            <input
                type="text"
                placeholder='Электронная почта'
                className='regInput'
                {...register('email',
                    {
                        required: true,
                        value: isAuth?.authState.user?.email
                    })}/>
            {errors.email && <p className='wrong-message'>Это поле обязательно</p>}
            <label className='regLabel'>
                Форма участия:
                <select
                    id="form-of-participation"
                    // disabled={disabled}
                    {...register('visit_format_id', {required: true})}
                >
                    {/* {forum_visit_format[0]?.name === 'online' ?
                        <option value='1'>Онлайн</option> :
                        forum_visit_format[0]?.name === 'offline' ?
                            <option value='2'>Очно</option> : ''
                    }
                    {forum_visit_format[1]?.name === 'online' ?
                        <option value='1'>Онлайн</option> :
                        forum_visit_format[1]?.name === 'offline' ?
                            <option value='2'>Очно</option> : ''
                    }*/}
                    {
                        forum_visit_format.map(item => (
                            <option value={item.id} key={item.id}>
                                {item.name === 'online' ? 'Онлайн' : 'Очно'}
                            </option>
                        ))
                    }
                </select>
            </label>
            {
                visit_format_id == 2 &&
                <label className='regLabel'>
                    Категория участия:
                    <select
                        id="form-of-participation"
                        // disabled={disabled}
                        {...register('educational_organization_id', {required: true})}
                    >
                        {
                            orgList.map((d) => (
                                <option value={d.id} key={d.id}>
                                    {d.name}
                                </option>
                            ))
                        }
                    </select>
                </label>
            }
            <textarea
                placeholder='Ответы на какие вопросы вы бы хотели получить'
                className='regInput'
                {...register('questions')}
            />
            <span className='regInput__checkbox'>
                    <input type="checkbox" className='regInput__checkbox-galka' {...register('travel_certificate')}/>
                    <label htmlFor='comandirovochnoe'>Нужно подписать командировочные</label>
                </span>
            <span className='regInput__checkbox'>
                    <label htmlFor='comandirovochnoe'>Отправляя заявку на форум, вы соглашаетесь получать уведомления и рассылку по мероприятию</label>
                </span>
            <button className="button form-btn">Отправить</button>
            {/*{errors.root && <p className='wrong-message'>{checkErrorText('travel_certificate', errors)}</p>}*/}
            {errors.root && <p className='wrong-message'>Вы уже отправляли заявку на мероприятие</p>}
        </form>
    );
};

export default RegistrationForm;