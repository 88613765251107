import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { baseUrl } from "../../../constants/constants";
import Login from "../login";
import { useNavigate } from "react-router-dom";
import "./registration.scss";
import { errorToast, successToast } from "../../../shared/toastify";
import { useAuth } from "../../../components/authProvider/authProvider";
import eye from "../../../assets/view.svg";
import Logo from "../../../assets/logo.jpg";

import eyeOff from "../../../assets/no-view.svg";
import { Helmet } from "react-helmet-async";
const href =
  typeof window !== "undefined" ? window.location.href : "https://нцпти.рф/";

export function checkErrorText(inputName: string, errors: any) {
  return errors?.[inputName] && errors?.[inputName]?.message;
}

interface Props {
  auth: boolean;
  setAuth: React.Dispatch<React.SetStateAction<boolean>>;
}

const Registration = ({ setAuth }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<Login>();
  const navigate = useNavigate();
  const auth = useAuth();
  const [icon, setIcon] = useState(eye);
  const [inputType, setInputType] = useState("password");

  const togglePassword = () => {
    if (inputType === "password") {
      setInputType("text");
      setIcon(eyeOff);
    } else {
      setInputType("password");
      setIcon(eye);
    }
  };

    const submit: SubmitHandler<Login> = async (data) => {
        try {
            const res = await fetch(`${baseUrl}user/register`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            });
            if (res.status === 200) {
                //@ts-ignore
                ym(100105951,'reachGoal','registration')
                localStorage.setItem('access', 'yes');
                await auth?.authRequest();

        successToast("Вы успешно зарегестрировались!");

        const url = localStorage.getItem("urlForum");
        if (url) {
          navigate(url);
          return;
        }

        navigate("/account");
      } else if (res.status === 401) {
        setError("root", { type: "custom", message: "" });
      } else if (res.status === 409) {
        setError("root", { type: "sameEmail", message: "" });
        errorToast("Пользователь с такой почтой уже существует");
      } else if (res.status === 422) {
        setError("root", {
          type: "wrong-password",
          message:
            "Пароль должен состоять минимум из 8 символов, включать в себя спецсимволы, цифры и заглавные буквы",
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Helmet>
        <title>Регистрация | НЦПТИ</title>
        <meta
          name="description"
          content="Регистрация на странице Национального центра информационного противодействия терроризму и экстремизму в образовательной среде и сети Интернет"
        />
        <meta property="og:title" content="Регистрация | НЦПТИ" />
        <meta
          property="og:description"
          content="Регистрация на странице Национального центра информационного противодействия терроризму и экстремизму в образовательной среде и сети Интернет"
        />
        <meta property="og:image" content={Logo} />
        <meta property="og:url" content={href} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="page">
        <form onSubmit={handleSubmit(submit)} className="register-form">
          <h1 className="register-title">Регистрация</h1>
          <input
            type="email"
            placeholder="Введите ваш Email"
            className="register-input"
            {...register("email", { required: true })}
          />
          {errors.email && errors.email.type === "required" && (
            <p className="wrong-message">Это поле обязательно</p>
          )}
          {/*{errors.root && <p className='wrong-message'>Пользователь с такой почтой уже существует</p>}*/}
          <div className={"register-input__password"}>
            <input
              type={inputType}
              placeholder="Придумайте пароль"
              className="register-input"
              {...register("password", { required: true })}
            />
            <span className="password-icon" onClick={togglePassword}>
              <img alt="иконка" className="password-icon__img" src={icon} />
            </span>
          </div>
          <p
            style={{
              width: "400px",
            }}
          >
            Пароль должен содержать минимум 8 символов, буквы разного регистра,
            минимум одну цифру, минимум один из спецсимволов <br /> (!, @, #, $,
            %, ^, &, * и т.д.).
          </p>
          {errors.password && errors.password.type === "required" && (
            <p className="wrong-message">Это поле обязательно</p>
          )}
          <div className="register-approval">
            <span className="regInput__checkbox" style={{ margin: ".5rem 0" }}>
              <input
                type="checkbox"
                className="regInput__checkbox-galka"
                required
              />
              <label>
                <a
                  href="/soglasie.doc"
                  download
                  className="register-approval__link"
                >
                  Согласие{" "}
                </a>
                на обработку персональных данных
              </label>
            </span>
            <span className="regInput__checkbox">
              <input
                type="checkbox"
                className="regInput__checkbox-galka"
                required
              />
              <label>
                Ознакомлен с{" "}
                <a
                  href="/politika-konfidenczialnosti.docx"
                  download
                  className="register-approval__link"
                >
                  политикой конфиденциальности
                </a>
              </label>
            </span>
          </div>
          <div className="btns">
            <button className="button register-btn">Регистрация</button>
          </div>
          {errors?.root && (
            <p className="wrong-message">{errors.root.message}</p>
          )}
        </form>
      </div>
    </>
  );
};

export default Registration;
