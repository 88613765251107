import React, { useState } from "react";
import { INews } from "../../adminPage";
import { baseUrl } from "../../../../constants/constants";
import "./newsList.scss";
import Modal from "../../../../components/modal/modal";
import UpdateNewsForm from "../UpdateNews/UpdateNewsForm";

interface INewsList {
  news: INews;
  updateStatusNews: (cuurentNews: INews) => void;
}

const NewsList: React.FC<INewsList> = ({ updateStatusNews, news }) => {
    const [showUpdateNewsModal, setShowUpdateNewsModal] = useState(false)

    const closeModal = () =>{
        setShowUpdateNewsModal(false)
    }

  return (
    <section className="admin-settings__section">
        <img
          src={`${baseUrl}${news.image_url}`}
          alt="Картинка новости"
          className="admin-settings__img"
        />
        <p className="admin-settings__news-p">id: {news.id}</p>
        <h2 className="admin-settings__h2">{news.name}</h2>
        <p className="admin-settings__news-p">{news.description}</p>
        <p className="admin-settings__news-p">{news.source_link}</p>
        <div className='settings-buttons'>
            <button
                className='button updateBtn'
                onClick={() => setShowUpdateNewsModal(true)}
            >
                Обновить
            </button>
            {showUpdateNewsModal &&
                <Modal
                    active={showUpdateNewsModal}
                    title='Обновление новости'
                    сlose={closeModal}
                >
                    <UpdateNewsForm
                        news={news}
                        close={closeModal}
                    />
                </Modal>}
            <button
                className='button deleteBtn'
                onClick={() => {
                    updateStatusNews(news);
                }}
            >
                Удалить
            </button>
        </div>
    </section>
  );
};

export default NewsList;
