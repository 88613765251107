import React, {useState} from 'react';
import UpdateManual from "../UpdateManual/UpdateManual";
import {ICategoryManuals, IManualsSettings} from "../ManualsSettings";
import {baseUrl} from "../../../../../../../constants/constants";
import Modal from "../../../../../../../components/modal/modal";

interface IManualsList{
    manualsList: IManualsSettings;
    category: ICategoryManuals[],
    deleteManual: (currentManual: IManualsSettings) => void
}

const ManualsList:React.FC<IManualsList> = ({manualsList,category, deleteManual}) => {
    const[showUpdateManualModal, setShowUpdateManualModal] = useState(false)

    const closeModal = () => {
        setShowUpdateManualModal(false)
    }

    return (
        <tr>
            <td className="applications-table__tbody-td">
                <img
                    src={`${baseUrl}${manualsList.file_card_url}`}
                    alt='Изображение методички'
                    className='cards-settings__img'
                />
            </td>
            <td className="applications-table__tbody-td">{manualsList.name}</td>
            <td className="applications-table__tbody-td">
                <a
                    href={`${baseUrl}${manualsList.file_url}`}
                    className='admin-settings__a'
                >
                    {manualsList.file_url}
                </a>
            </td>
            <td className="applications-table__tbody-td">{manualsList.category_id === 10 ? 'Сборники профилактических мероприятий' : manualsList.category_id === 11 ? 'Чек-листы по работе с группами риска' : ''}</td>
            <td className="applications-table__tbody-td">
                <button
                    className="button updateBtn"
                    onClick={() => setShowUpdateManualModal(true)}
                >
                    Обновить
                </button>
                {showUpdateManualModal &&
                    <Modal
                        active={showUpdateManualModal}
                        title='Обновите информацию'
                        сlose={closeModal}
                    >
                        <UpdateManual
                            manual={manualsList}
                            category={category}
                            close={closeModal}
                        />
                    </Modal>
                }
                <button
                    className="button deleteBtn"
                    onClick={() => deleteManual(manualsList)}
                >
                    Удалить
                </button>
            </td>
        </tr>

)
    ;
};

export default ManualsList;