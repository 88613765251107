import React, {useState} from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import {errorToast, successToast} from "../../../../../../shared/toastify";
import {baseUrl} from "../../../../../../constants/constants";

export interface ICreatePrivateForum {
    name: string,
    description: string,
    forum_location: string,
    start_date_training: Date,
    end_date_training: Date,
    is_active: boolean
}

interface Props {
    close: () => void,
}

const CreatePrivateForum = ({close}: Props) => {
    const {
        register,
        handleSubmit,
        formState: {errors},
        setError, watch
    } = useForm<ICreatePrivateForum>()

    const submit: SubmitHandler<ICreatePrivateForum> = async (data: any) => {
        try {
            const res = await fetch(`${baseUrl}private_forum`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if (res.status === 200) {
                successToast('Мероприятие успешно создано. Обновите страницу');
            } else if (res.status === 401) {
                setError('root', {type: 'custom', message: ''})
                errorToast('Ошибка 401')
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <section>
            <form
                className="regForm"
                onSubmit={handleSubmit(submit)}
            >
                <input
                    type="text"
                    className="regInput"
                    placeholder='Название мероприятия'
                    {...register('name', {required: true})}
                />
                {errors.name && <p className='wrong-message'>Это поле обязательно</p>}
                <textarea
                    className="regInput"
                    placeholder='Описание мероприятия'
                    {...register('description', {required: true})}
                />
                {errors.description && <p className='wrong-message'>Это поле обязательно</p>}
                <input
                    type="text"
                    className="regInput"
                    placeholder='Место проведения мероприятия'
                    {...register('forum_location', {required: true})}
                />
                {errors.forum_location && <p className='wrong-message'>Это поле обязательно</p>}

                <label className='regLabel'>
                    Дата начала мероприятия
                    <input
                        type="date"
                        className="regInput"
                        {...register('start_date_training', {required: true})}
                    />
                </label>
                {errors.start_date_training && <p className='wrong-message'>Это поле обязательно</p>}
                <label className='regLabel'>
                    Дата окончания мероприятия
                    <input
                        type="date"
                        className="regInput"
                        {...register('end_date_training', {required: true})}
                    />
                </label>
                <span className="regInput__checkbox">
        <input
            type="checkbox"
            className="regInput__checkbox-galka"
            {...register("is_active")}
        />
        <label>Сделать активным?</label>
      </span>
                <button className='button form-btn'>Создать</button>
            </form>
        </section>
    );
};

export default CreatePrivateForum;