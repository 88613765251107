import React, {useState} from "react";
import "./header.scss";
import Logo from "../../assets/logo-header.png";
import {
    FaTelegram,
    FaVk,
    FaYoutube,
    FaDoorClosed,
    FaDoorOpen,
    FaRegUserCircle,
    FaSignInAlt
} from "react-icons/fa";
import {Link} from "react-router-dom";
import {baseUrl} from "../../constants/constants";
import {useAuth} from "../authProvider/authProvider";

const Header = () => {
    const authContext = useAuth();
    const access = localStorage.getItem("access");

    const [ruHover, setState] = useState<boolean>(false);
    const [yaHover, setYaHover] = useState<boolean>(false);

    return (
        <div className="header">
            <div className='header-main'>
                <div className="header__logo">
                    <Link to="/" className="header__logo-link" title="На главную">
                        <img src={Logo} alt="Логотип НЦПТИ" className="header__logo-img"/>
                    </Link>
                </div>
                <div className="header__center">
                    {/*<search*/}
                    {/*    role="search"*/}
                    {/*    className='header__center-search'*/}
                    {/*>*/}
                    {/*    <input*/}
                    {/*        type="text"*/}
                    {/*        placeholder='Поиск...'*/}
                    {/*        className='header__center-input'*/}
                    {/*    />*/}
                    {/*</search>*/}

                </div>
                <section className="header__social-links">
                    <div className="header__social-links-blog">
                        <Link to="news">
                            <button className="button header__btn">События недели</button>
                        </Link>
                    </div>
                    <div>
                        <a href="https://ncpti.ru/umcz/" target='_blank'>
                            <button className="button header__btn">Сеть центров</button>
                        </a>
                    </div>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        gap: '5px'
                    }}>
                        <a href="https://vk.com/ncpti_rnd" target="_blank" title="Наш ВК">
                            <FaVk className="social-icon"/>
                        </a>
                        <a href="https://t.me/ncpti" target="_blank" title="Наш телеграм">
                            <FaTelegram className="social-icon"/>
                        </a>
                        <a href="https://dzen.ru/ncpti" target="_blank" title="Yandex Dzen"
                           onMouseEnter={() => setYaHover(true)}
                           onMouseLeave={() => setYaHover(false)}
                        >
                            <svg width="25" height="25" viewBox="0 0 169 169" fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                                className={'social-icon'}
                            >
                                <g clip-path="url(#clip0_3014_2214)">
                                    <mask id="mask0_3014_2214" maskUnits="userSpaceOnUse"
                                          x="0" y="0" width="169" height="169">
                                        <path d="M168.409 0H0.368652V168.04H168.409V0Z" fill="white"/>
                                    </mask>
                                    <g mask="url(#mask0_3014_2214)">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M84.7036 168.01H84.0337C50.6797 168.01 31.1732 164.651 17.5856 151.333C3.59808 137.066 0.368652 117.699 0.368652 84.4749V83.5351C0.368652 50.311 3.59808 30.8045 17.5856 16.677C31.3031 3.3594 50.6797 0 84.0437 0H84.7136C117.938 0 137.444 3.3594 151.152 16.677C165.139 30.9445 168.369 50.311 168.369 83.5351V84.4749C168.369 117.709 165.139 137.206 151.152 151.333C137.434 164.651 118.068 168.01 84.7036 168.01ZM147.209 81.5308C147.849 81.5608 148.369 82.0906 148.369 82.7304H148.359V85.4096C148.359 86.0594 147.839 86.5892 147.199 86.6092C124.236 87.4789 110.271 90.398 100.484 100.185C90.7067 109.962 87.7876 123.908 86.9079 146.83C86.8779 147.47 86.348 147.99 85.7082 147.99H83.0191C82.3692 147.99 81.8394 147.47 81.8195 146.83C80.9397 123.908 78.0206 109.962 68.2437 100.185C58.4667 90.408 44.4911 87.4789 21.5283 86.6092C20.8885 86.5792 20.3687 86.0494 20.3687 85.4096V82.7304C20.3687 82.0806 20.8885 81.5508 21.5283 81.5308C44.4911 80.661 58.4568 77.742 68.2437 67.955C78.0406 58.1581 80.9597 44.1624 81.8295 21.1596C81.8594 20.5198 82.3893 20 83.0291 20H85.7082C86.358 20 86.8879 20.5198 86.9079 21.1596C87.7775 44.1724 90.6967 58.1581 100.494 67.955C110.271 77.732 124.246 80.661 147.209 81.5308Z"
                                              fill={yaHover ? '#5856d6' : '#C1C2D1'}/>
                                    </g>
                                </g>
                                <defs>
                                    <clipPath id="clip0_3014_2214">
                                        <rect width="169" height="169" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </a>
                        <a
                            href="https://rutube.ru/channel/25826771/"
                            target="_blank"
                            title="Наш RuTube-канал"
                        >
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                 xmlns="http://www.w3.org/2000/svg" className={'social-icon'}
                                 onMouseEnter={() => setState(true)}
                                 onMouseLeave={() => setState(false)}
                            >
                                <g clip-path="url(#clip0_12_17218)">
                                    <path
                                        d="M15.3996 12.0699H8.17243V9.21004H15.3996C15.8218 9.21004 16.1153 9.28362 16.2626 9.41212C16.4099 9.54062 16.5012 9.77894 16.5012 10.1271V11.154C16.5012 11.5208 16.4099 11.7591 16.2626 11.8876C16.1153 12.0161 15.8218 12.071 15.3996 12.071V12.0699ZM15.8954 6.51379H5.10742V18.7441H8.17243V14.7651H13.8209L16.5012 18.7441H19.9334L16.9783 14.7464C18.0678 14.585 18.557 14.2511 18.9604 13.7009C19.3639 13.1506 19.5662 12.2709 19.5662 11.0969V10.1798C19.5662 9.48351 19.4925 8.93328 19.3639 8.51154C19.2353 8.08981 19.0154 7.72299 18.7032 7.39351C18.3734 7.0816 18.0062 6.86194 17.5653 6.71478C17.1245 6.58628 16.5737 6.5127 15.8954 6.5127V6.51379Z"
                                        fill={ruHover ? '#5856d6' : '#C1C2D1'}></path>
                                    <path
                                        d="M36.9843 0.396693C36.9843 7.15191 31.5081 12.6281 24.7529 12.6281C23.3795 12.6281 22.0589 12.4017 20.8265 11.9843C20.8529 11.6936 20.8645 11.3965 20.8645 11.0966V10.1795C20.8645 9.40085 20.7832 8.71448 20.6058 8.13279C20.4145 7.50551 20.0863 6.96566 19.6459 6.50089L19.6215 6.47509L19.5957 6.45067C19.1264 6.00685 18.5945 5.69001 17.9772 5.48396L17.9535 5.47603L17.9295 5.46903C17.3419 5.29778 16.6636 5.21512 15.8965 5.21512H14.5992V5.21622H13.5076C12.8729 3.73729 12.5215 2.10804 12.5215 0.396693C12.5215 -6.35853 17.9977 -11.8347 24.7529 -11.8347C31.5081 -11.8347 36.9843 -6.35853 36.9843 0.396693Z"
                                        fill={ruHover ? '#5856d6' : '#C1C2D1'}></path>
                                </g>
                                <rect x="0.474846" y="0.582512" width="24.0912" height="24.0912" rx="5.76463"
                                      stroke={ruHover ? '#5856d6' : '#C1C2D1'}
                                      stroke-width="0.371567"></rect>
                                <defs>
                                    <clipPath id="clip0_12_17218">
                                        <rect x="0.289062" y="0.396729" width="24.4628" height="24.4628" rx="5.95041"
                                              fill="white"></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                        </a>
                        {authContext?.authState.isAuth ? (
                            <>
                                <Link to="account" title="Личный кабинет">
                                    <FaRegUserCircle className="social-icon"/>
                                </Link>
                            </>
                        ) : (
                            <Link to="/login" title="Войти">
                                <FaSignInAlt className="social-icon"/>
                            </Link>
                        )}
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Header;
