import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import './fonts/Mulish/static/Mulish-Regular.ttf'
import {BrowserRouter} from "react-router-dom";
import {AuthProvider} from "./components/authProvider/authProvider";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
        <AuthProvider>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <App />
        </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
