import React, {useState} from 'react';
import {IOnlineCourseSettings} from "../../CoursesSettingsPage";
import UpdateOnlineCourse from "../UpdateOnlineCourse/UpdateOnlineCourse";
import {baseUrl} from "../../../../../../constants/constants";
import Modal from "../../../../../../components/modal/modal";


interface IOnlineCourseList{
    on_courses: IOnlineCourseSettings;
    deleteCourse: (currentCourse: IOnlineCourseSettings) => void
}

const OnlineCourseList: React.FC<IOnlineCourseList> = ({on_courses, deleteCourse}) => {
    const [showUpdateModal, setShowUpdateModal] = useState(false)

    const closeModal = () => {
        setShowUpdateModal(false)
    }

    return (
        <section className='admin-settings__section'>
            <img
                src={`${baseUrl}${on_courses.image_url}`}
                alt="Изображение курса"
                className="admin-settings__img"
            />
            <h2 className="admin-settings__h2">{on_courses.name}</h2>
            <p className="admin-settings__p">Описание: {on_courses.description}</p>
            <p className="admin-settings__p">Цена: {on_courses.price} рублей</p>
            <div className='settings-buttons'>
                <button
                    className='button updateBtn'
                    onClick={() => setShowUpdateModal(true)}
                >
                    Обновить
                </button>
                {showUpdateModal &&
                    <Modal
                        active={showUpdateModal}
                        title='Обновите информацию о курсе'
                        сlose={closeModal}
                    >
                        <UpdateOnlineCourse
                            course={on_courses}
                            close={closeModal}
                        />
                    </Modal>}
                <button
                    className='button deleteBtn'
                    onClick={() => {
                        deleteCourse(on_courses)
                    }}
                >
                    Удалить
                </button>
            </div>
        </section>
    );
};

export default OnlineCourseList;