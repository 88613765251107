import React from 'react';
import {baseUrl} from "../../../../../../constants/constants";
import {errorToast, infoToast, successToast} from "../../../../../../shared/toastify";
import axios, {AxiosError} from "axios";
import {IBlankForum} from "./ForumTable";

type Props = {
    onUpdate: () => void
} & IBlankForum

const BlankForum = (
    {
        email,
        full_name,
        full_name_declination,
        age,
        number_phone,
        region_residence,
        place_of_work,
        position_work,
        travel_certificate,
        visit_format,
        date_creation,
        forum,
        id,
        is_approved,
        onUpdate
    }: Props) => {

    const onApprove = () => {
        axios.put(`${baseUrl}blank_forum/approved/${id}`, {
            is_approved: true
        }).then(() => {
            successToast('Успешно одобрен');
            onUpdate()
        }).catch((e) => {
            if (e instanceof AxiosError) {
                //@ts-ignore
                const message = e.response.data.detail;
                if (message === 'Заявка уже была одобрена.') {
                    infoToast(message)
                    return;
                }
            }
            errorToast('Ошибка')
        })
    }
    const onDisapprove = () => {
        axios.put(`${baseUrl}blank_forum/approved/${id}`, {
            is_approved: false
        })
            .then(() => {
                successToast('Заявка отклонена');
                onUpdate();
            })
            .catch((e) => {
                console.log(e)
                if (e instanceof AxiosError) {
                    //@ts-ignore
                    const message = e.response.data.detail;
                    if (message?.includes('Заявка уже была')) {
                        infoToast(message);
                        return;
                    }
                }
                errorToast('Ошибка')
            })
    }

    const date_blank_forum = new Date(date_creation).toLocaleString('ru', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    });

    return (
        <tr>
            <td className='applications-table__tbody-td'>{forum.name}</td>
            <td className='applications-table__tbody-td'>{email}</td>
            <td className='applications-table__tbody-td'>{full_name}</td>
            <td className='applications-table__tbody-td'>{full_name_declination}</td>
            <td className='applications-table__tbody-td'>{age}</td>
            <td className='applications-table__tbody-td'>{number_phone}</td>
            <td className='applications-table__tbody-td'>{region_residence}</td>
            <td className='applications-table__tbody-td'>{place_of_work}</td>
            <td className='applications-table__tbody-td'>{position_work}</td>
            <td className='applications-table__tbody-td'>{travel_certificate ? 'Да' : 'Нет'}</td>
            <td className='applications-table__tbody-td'>{visit_format.name}</td>
            <td className='applications-table__tbody-td'>{date_blank_forum}</td>
            {
                visit_format.name !== 'online' &&
                <>
                    {
                        is_approved === null && (
                            <>
                                <td className='applications-table__tbody-td'>
                                    <button className={'button admin-btn'} onClick={onApprove}>
                                        Одобрить
                                    </button>
                                </td>
                                <td className='applications-table__tbody-td'>
                                    <button className={'button admin-btn'} onClick={onDisapprove}>
                                        Отклонить
                                    </button>
                                </td>
                            </>
                        )
                    }
                    {
                        is_approved && (
                            <td className='applications-table__tbody-td'>
                                <span>Одобрено</span>
                                <button className={'button admin-btn'} onClick={onDisapprove}>
                                    Отклонить
                                </button>
                            </td>
                        )
                    }
                    {
                        is_approved === false && (
                            <td className='applications-table__tbody-td'>
                                <span>Отклонено</span>
                                <button className={'button admin-btn'} onClick={onApprove}>
                                    Одобрить
                                </button>
                            </td>
                        )
                    }
                </>
            }
        </tr>
    );
};

export default BlankForum;