import React, {useEffect, useState} from 'react';
import {baseUrl} from "../../../../../../constants/constants";
import {Link} from "react-router-dom";
import {dwnldOfflineCourse} from "../../../../../../components/excel-export/excel-export";
import BlankOffline from "./BlankOffline";

export interface IBlankOfflineCourse {
    id: number,
    contact_email: string,
    contact_full_name: string,
    contact_number_phone: string,
    event_location: string,
    topics_discussion: string,
    desired_time: Date,
    date_creation: Date,
    offline_course: {
        id: number,
        name: string
    }
}

const OfflineTable = () => {
    const [offlineBlanks, setOfflineBlanks] = useState<IBlankOfflineCourse[]>([]);

    useEffect(() => {
        fetch(`${baseUrl}blank_offline_course?sort_date=true`)
            .then((res) => res.json())
            .then((data) => {
                setOfflineBlanks(data)
            })
            .catch((err) => {
                console.log(err)
            });
    }, []);

    return (
        <section className="admin-section">
            <h1 className="admin-title__h1-media">Редактирование секции "Заявки"</h1>
            <section className="admin-media__btns-section">
                <Link to='/admin-panel/applications/forums'>
                    <button className="button admin-media__btn">Форумы</button>
                </Link>
                <Link to='/admin-panel/applications/online'>
                    <button className="button admin-media__btn">Онлайн-курсы</button>
                </Link>
                <Link to='#'>
                    <button className="button admin-media__btn-active">Оффлайн-курсы</button>
                </Link>
                <Link to='/admin-panel/applications/obzor'>
                    <button className="button admin-media__btn">Обзор.НЦПТИ</button>
                </Link>
            </section>
            <div className="one-table">
                <div className="admin-buttons">
                    <h3 className='admin-title__h3'>Очное обучение</h3>
                    <button className="button admin-btn" onClick={dwnldOfflineCourse}>Excel</button>
                </div>
                <table
                    className='applications-table'
                >
                    <thead className='applications-table__thead'>
                    <tr>
                        <th className='applications-table__thead-th'>Мероприятие</th>
                        <th className='applications-table__thead-th'>Email</th>
                        <th className='applications-table__thead-th'>Имя</th>
                        <th className='applications-table__thead-th'>Телефон</th>
                        <th className='applications-table__thead-th'>Место проведения мероприятия</th>
                        <th className='applications-table__thead-th'>Темы для обсуждения</th>
                        <th className='applications-table__thead-th'>Дата проведения</th>
                        <th className='applications-table__thead-th'>Дата подачи</th>
                    </tr>
                    </thead>
                    {offlineBlanks.map((item) =>
                        <tbody className='applications-table__tbody' key={item.id}>
                        <BlankOffline {...item}/>
                        </tbody>
                    )}
                </table>
            </div>

        </section>
    );
};

export default OfflineTable;