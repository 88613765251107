import React from 'react';
import {baseUrl} from "../../../../../../constants/constants";
import {IBlankWriter} from "./WriterTable";

const BlankWriter = ({
    id,
    email,
    full_name,
    number_phone,
    title_article,
    busyness,
    file_data_user_url,
    file_consent_data_processing_url,
    file_anti_plagiarism_url,
    file_article_url,
    date_creation,
    theme_article
                     }: IBlankWriter) => {

    const date_blank_writer = new Date(date_creation).toLocaleString('ru', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    });

    return (
        <tr>
            <td className='applications-table__tbody-td'>{theme_article.name}</td>
            <td className='applications-table__tbody-td'>{full_name}</td>
            <td className='applications-table__tbody-td'>{email}</td>
            <td className='applications-table__tbody-td'>{number_phone}</td>
            <td className='applications-table__tbody-td'>{busyness}</td>
            <td className='applications-table__tbody-td'>{title_article}</td>
            <td className='applications-table__tbody-td'><a href={`${baseUrl}${file_article_url}`} target='_blank' >Статья</a></td>
            <td className='applications-table__tbody-td'><a href={`${baseUrl}${file_anti_plagiarism_url}`} target='_blank' >Антиплагиат</a></td>
            <td className='applications-table__tbody-td'><a href={`${baseUrl}${file_data_user_url}`} target='_blank' >Данные пользователя</a></td>
            <td className='applications-table__tbody-td'><a href={`${baseUrl}${file_consent_data_processing_url}`} target='_blank' >Файл обработки ПДН</a></td>
            <td className='applications-table__tbody-td'>{date_blank_writer}</td>
        </tr>
    );
};

export default BlankWriter;