import React, { useEffect, useState } from "react";
import "./obzor.scss";
import { Link, useNavigate } from "react-router-dom";
import ImageTitle from "../../../assets/title_cards/obzor_.webp";
import Modal from "../../../components/modal/modal";
import ObzorRegForm from "./components/obzorRegistrationForm/obzorRegForm";
import { baseUrl } from "../../../constants/constants";
import { useAuth } from "../../../components/authProvider/authProvider";
import ModalLogin from "../../../components/modalLogin/modalLogin";
import Preview from "../../../assets/cards/journal_.webp";
import { Helmet } from "react-helmet-async";
const href =
  typeof window !== "undefined" ? window.location.href : "https://нцпти.рф/";

export interface Props {
  close: () => void;
}
interface IBanner {
  id: number;
  name: string;
  file_url: string;
}

const Obzor = () => {
  const [showModal, setShowModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const isAuth = useAuth();
  const [banner, setBanner] = useState<IBanner[]>([]);
  const [mobileBanner, setMobileBanner] = useState<IBanner[]>([]);

  useEffect(() => {
    fetch(`${baseUrl}gallery_images?category_id=19`)
      .then((res) => res.json())
      .then((data: IBanner[]) => {
        const banner = data.filter((item) => item.id === 85);
        setBanner(banner);
        console.log(banner);
      });

    fetch(`${baseUrl}gallery_images?category_id=19`)
      .then((res) => res.json())
      .then((data: IBanner[]) => {
        const bannerMobile = data.filter((el) => el.id === 87);
        setMobileBanner(bannerMobile);
      });
  }, []);

  const openPopupWithAuth = () => {
    if (isAuth?.authState?.isAuth) {
      setShowModal(true);
    } else {
      setShowLoginModal(true);
    }
  };

  const subscribe = () => {
    if (isAuth?.authState.isAuth) {
       // @ts-ignore
       ym(100105951,'reachGoal','obzor-subscribe')
      isAuth.updateUser({ email_subscription: true });
    } else {
      setShowLoginModal(true);
    }
  };

  const unsubscribe = () => {
    if (isAuth?.authState.isAuth) {
      // @ts-ignore
      ym(100105951,'reachGoal','obzor-unsubscribe')
      isAuth.updateUser({ email_subscription: false })
    } else {
      setShowLoginModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Helmet>
        <title>Обзор.НЦПТИ</title>
        <meta
          name="description"
          content="Журнал для информирования и эффективного взаимодействия самых разных экспертов служб и ведомств, представителей власти и гражданского сообщества"
        />
        <meta property="og:title" content="Обзор.НЦПТИ" />
        <meta
          property="og:description"
          content="Журнал для информирования и эффективного взаимодействия самых разных экспертов служб и ведомств, представителей власти и гражданского сообщества"
        />
        <meta property="og:image" content={Preview} />
        <meta property="og:url" content={href} />
        <meta property="og:type" content="website" />
      </Helmet>
      <h1 className="main_title">Журнал Обзор.НЦПТИ</h1>
      {banner &&
        banner.map((i) => (
          <section className="title-image">
            <img
              src={`${baseUrl}${i.file_url}`}
              alt="Баннер Обзор.НЦПТИ"
              loading="lazy"
              className="title-image__img"
            />
          </section>
        ))}

      {mobileBanner &&
        mobileBanner.map((e) => (
          <section className="title-image__mobile">
            <img
              src={`${baseUrl}${e.file_url}`}
              alt="Баннер Обзор.НЦПТИ мобильный"
              loading="lazy"
              className="title-image__img-mobile"
            />
          </section>
        ))}

      <div className="page-obzor">
        <div className="page">
          <section className="obzor-description">
            <img
              src="https://ncpti.ru/wp-content/uploads/2022/02/obzor-nczpti-zhurnal-min.png"
              alt="Обложка журнала Обзор.НЦПТИ"
              loading="lazy"
              className="obzor-description__img"
            />
            <div className="obzor-description__text">
              <h1 className="title-h1 obzor-description__text-h1">
                Обзор.НЦПТИ
              </h1>
              <p>
                «Обзор.НЦПТИ» предназначен для информирования и эффективного
                взаимодействия самых различных экспертов служб и ведомств,
                представителей власти, научного и гражданского сообщества в
                сфере противодействия терроризму, обеспечения национальной
                безопасности, создания условий для гармонизации межэтнических,
                межконфессиональных и межнациональных отношений.
                <br />
                <br />
                Журнал учреждён в 2012 году и за прошедшие годы стал известен
                широкой аудитории учёных и специалистов, с 2015 года
                «Обзор.НЦПТИ» представлен в
                <a
                  href="https://www.elibrary.ru/defaultx.asp"
                  target="_blank"
                  className="obzor-description__text-a"
                >
                  {" "}
                  Научной Электронной библиотеке eLIBRARY.RU{" "}
                </a>{" "}
                и включен в Российский индекс научного цитирования (РИНЦ), а
                также в базе научных статей
                <a
                  href="https://cyberleninka.ru/"
                  target="_blank"
                  className="obzor-description__text-a"
                >
                  {" "}
                  CyberLeninka
                </a>
                <br />
                <br />С 2018 года журнал выходит 4 раза в год.
              </p>
              <Link to="catalog">
                <button className="button obzor-description__text-btn">
                  Каталог журналов
                </button>
              </Link>
            </div>
          </section>
          <section className="obzor-deadlines">
            <div className="obzor-deadlines__text">
              <h3 className="title-h3 obzor-deadlines__text-h3">
                Материалы принимаются в следующие сроки:
              </h3>
              <p className="obzor-deadlines__text-p">
                № 1(40) — до 10 февраля 2025 года (тема: «Фальсификация
                истории»).
              </p>
              <p className="obzor-deadlines__text-p">
                № 2(41) — до 10 мая 2025 года (тема: «Исламизм»).
              </p>
              <p className="obzor-deadlines__text-p">
                № 3(42) — до 10 августа 2025 года (тема: «Искусственный
                интеллект и безопасность»).
              </p>
              <p className="obzor-deadlines__text-p">
                № 4(43) — до 10 ноября 2025 года (тема: «Профилактика как часть
                воспитания в вузе»).
              </p>
              {isAuth?.authState.user?.email_subscription ? (
                <button
                  onClick={unsubscribe}
                  className="button"
                >
                  Подписка оформлена
                </button>
              ) : (
                <button onClick={subscribe} className="button">
                  Подписаться на рассылку
                </button>
              )}
            </div>
            <div className="obzor-deadlines__img">
              <img
                src="https://ncpti.ru/wp-content/uploads/2023/04/obzor-2023.png"
                alt="Обложка журнала Обзор.НЦПТИ"
                className="obzor-deadlines__imgs"
                loading="lazy"
              />
              <img
                src="https://ncpti.ru/wp-content/uploads/2022/12/2022-12-30_20-34-10.png"
                alt="Обложка журнала Обзор.НЦПТИ"
                className="obzor-deadlines__imgs"
                loading="lazy"
              />
            </div>
          </section>
          <section className="obzor-themes">
            <h3 className="title-h3 obzor-themes__h3">
              Приглашаем авторов опубликоваться по следующим темам:
            </h3>
            <p className="obzor-themes__p">
              — Актуальные вопросы и проблемы в сфере противодействия идеологии
              терроризма и экстремизма.
            </p>
            <p className="obzor-themes__p">
              — Позитивный контент как приоритетное направление профилактической
              деятельности.
            </p>
            <p className="obzor-themes__p">
              — Вопросы законодательства в сфере противодействия терроризму и
              экстремизму: Россия и мир.
            </p>
            <p className="obzor-themes__p">
              — Взгляд изнутри: молодёжь о терроризме, экстремизме и угрозах
              безопасности.
            </p>
            <p className="obzor-themes__p">
              — Эффективные практики профилактики идеологии экстремизма и
              терроризма в иолодёжной и образовательных средах.
            </p>
            <p className="obzor-themes__p">
              — Проблемы и актуальные вопросы в системе профилактики
              противодействия терроризму и экстремизму.
            </p>
            <p className="obzor-themes__p">
              — Роль интернета и цифровизации процессов в борьбе с терроризмом и
              экстремизмом.
            </p>
            <p className="obzor-themes__p">
              — История терроризма: трансформация явления во времени, формах и
              содержании.
            </p>
            <p className="obzor-themes__p">
              — Факторы радикализации и распространения религиозного экстремизма
              молодёжи в России и странах СНГ.
            </p>
            <p className="obzor-themes__p">
              — Особенности националистического экстремизма в России и странах
              СНГ.
            </p>
          </section>
          <section className="obzor-application">
            <a
              href="/PDF-for-authors.pdf"
              target="_blank"
              className="obzor-application__a"
            >
              Оформление статьи
            </a>
            <button
              className="button obzor-application__btn"
              onClick={() => openPopupWithAuth()}
            >
              Подать заявку на публикацию
            </button>
            {showModal && (
              <Modal
                active={showModal}
                title={
                  "Оставьте свои данные, наши специалисты свяжутся с вами для уточнения информации"
                }
                сlose={closeModal}
              >
                <ObzorRegForm close={closeModal} />
              </Modal>
            )}
            {showLoginModal && (
              <ModalLogin
                showLoginModal={showLoginModal}
                setShowLoginModal={setShowLoginModal}
              />
            )}
            <p className="obzor-application__p">
              Сотрудники редакции нашего журнала готовы ответить на вопросы,
              <br />
              касающиеся условий партнёрства и публикации статей.
            </p>
            <p className="obzor-application__p">
              <strong>Контактная информация:</strong> 344010, Ростов-на-Дону,
              ул. Города Волос, д.6,
              <br />
              e-mail:{" "}
              <a href="mailto:info@ncpti.ru" className="obzor-application__a">
                info@ncpti.ru
              </a>
              , тел. редакции:{" "}
              <a href="tel:88632012822" className="obzor-application__a">
                8(863)201-28-22
              </a>
            </p>
            <p className="obzor-application__p">
              Выпускающий редактор: Жученко Виктория Сергеевна
            </p>
            <p className="obzor-application__p">
              Редактор: Венцель Сергей Владимирович
            </p>
          </section>
        </div>
      </div>
    </>
  );
};

export default Obzor;
