import React from "react";
import {IForum} from "../../../ForumsSettingsPage";
import {SubmitHandler, useForm} from "react-hook-form";
import {isFormatChoosen, getYearMothDayFormat, formAdapter, getTime} from "./utils";
import {baseUrl} from "../../../../../../../constants/constants";
import {successToast} from "../../../../../../../shared/toastify";

interface IUpdateForum {
    forum: IForum;
    close: () => void;
}

export interface IFormState extends IForum {
    image: string;
    forum_visit_format_offline: boolean;
    forum_visit_format_online: boolean;
    start_registration_time: string,
    end_registration_time: string,
}

const UpdateForum: React.FC<IUpdateForum> = ({forum, close}) => {
    console.log(forum)
    const {
        register,
        handleSubmit,
        formState: {errors},
        setError,
    } = useForm<IFormState>({
        defaultValues: {
            name: forum.name,
            description: forum.description,
            supplement: forum.supplement,
            forum_visit_format_offline: isFormatChoosen(
                forum.forum_visit_format,
                "offline"
            ),
            forum_visit_format_online: isFormatChoosen(
                forum.forum_visit_format,
                "online"
            ),
            start_date_training: getYearMothDayFormat(
                new Date(forum.start_date_training)
            ),
            end_date_training: getYearMothDayFormat(
                new Date(forum.end_date_training)
            ),
            //@ts-ignore
            start_registration_time: getTime(forum['start_registration_time']),
            //@ts-ignore
            end_registration_time: getTime(forum['end_registration_time']),
            is_active: forum.is_active,
        },
    });

    const submit: SubmitHandler<IFormState> = async (data: any) => {
        try {
            const formAdapted: any = formAdapter(data);
            const formData = new FormData();
            console.log(formAdapted);

            for (let key in formAdapted) {
                if (key == "image") {
                    if (!!formAdapted[key][0]) {
                        formData.append(key, formAdapted[key][0]);
                    }
                }

                if (!Array.isArray(formAdapted[key]) && key !== "image") {
                    formData.append(key, data[key]);
                }
                if (Array.isArray(formAdapted[key])) {
                    formAdapted[key].forEach((el: number) =>
                        formData.append(key, String(el))
                    );
                }
                if (key.includes('_registration_time')) {
                    formData.set(key, `${data[key]}Z`);
                }
            }

            const res = await fetch(`${baseUrl}forum/${forum.id}`, {
                method: "PUT",
                body: formData,
            });
            if (res.status === 200) {
                successToast("Данные о мероприятии успешно обновлены. Обновите страницу");
                close();
            } else if (res.status === 401) {
                setError("root", {type: "custom", message: ""});
            }
        } catch (err) {
            console.log(err);
        }
    };

    const forum_start_date = new Date(forum.start_date_training).toLocaleString(
        "ru",
        {
            month: "long",
            day: "numeric",
            year: "numeric",
        }
    );

    const forum_end_date = new Date(forum.end_date_training).toLocaleString(
        "ru",
        {
            month: "long",
            day: "numeric",
            year: "numeric",
        }
    );

    return (
        <form className="regForm" onSubmit={handleSubmit(submit)}>
            <input
                type="text"
                className="regInput"
                placeholder="Название мероприятия"
                {...register("name")}
            />
            <textarea
                className="regInput"
                placeholder="Описание мероприятия"
                {...register("description")}
            />
            <textarea
                className="regInput"
                placeholder="Дополнение к описанию"
                {...register("supplement")}
            />
            <input
                type="text"
                className="regInput"
                placeholder="Место проведения мероприятия"
                defaultValue={forum.forum_location}
                {...register("forum_location")}
            />

            <fieldset className="account-label">
                Формат мероприятия
                <span>
          <input
              type="checkbox"
              className="regInput__checkbox-galka"
              value="1"
              {...register("forum_visit_format_online")}
          />
          <label className="account-label__radio-label">Онлайн</label>
        </span>
                <span>
          <input
              type="checkbox"
              className="regInput__checkbox-galka"
              value="2"
              {...register("forum_visit_format_offline")}
          />
          <label className="account-label__radio-label">Очно</label>
        </span>
            </fieldset>

            <label className="regLabel">
                Дата начала мероприятия
                <input
                    type="date"
                    className="regInput"
                    defaultValue={forum_start_date}
                    {...register("start_date_training")}
                />
            </label>
            <label className="regLabel">
                Дата окончания мероприятия
                <input
                    type="date"
                    className="regInput"
                    defaultValue={forum_end_date}
                    {...register("end_date_training")}
                />
            </label>
            {
                (
                    <>
                        <label className='regLabel'>
                            Время начала регистрации
                            <input
                                type="time"
                                className="regInput"
                                {...register('start_registration_time', {required: true})}
                            />
                        </label>
                        <label className='regLabel'>
                            Время конца регистрации
                            <input
                                type="time"
                                className="regInput"
                                {...register('end_registration_time', {required: true})}
                            />
                        </label>
                    </>
                )
            }
            <span className="regInput__file">
        <p>Изображение мероприятия</p>
        <input
            type="file"
            {...register("image")}
            accept="image/jpg, image/jpeg,image/webp, image/png"
        />
      </span>
            <span className="regInput__checkbox">
        <input
            type="checkbox"
            className="regInput__checkbox-galka"
            {...register("is_active")}
        />
        <label>Сделать активным?</label>
      </span>
            <button className="button form-btn">Обновить</button>
        </form>
    );
};

export default UpdateForum;
