import React, { useState } from "react";
import "./login.scss";
import { Link, useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { baseUrl } from "../../constants/constants";
import eye from "../../assets/view.svg";
import eyeOff from "../../assets/no-view.svg";
import { useAuth } from "../../components/authProvider/authProvider";
import Preview from "../../assets/logo.jpg";
import { Helmet } from "react-helmet-async";
const href =
  typeof window !== "undefined" ? window.location.href : "https://нцпти.рф/";

interface Login {
  email?: string;
  password?: string;
}

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm<Login>();

  const navigate = useNavigate();
  const [icon, setIcon] = useState(eye);
  const [inputType, setInputType] = useState("password");
  const auth = useAuth();

  const togglePassword = () => {
    if (inputType === "password") {
      setInputType("text");
      setIcon(eyeOff);
    } else {
      setInputType("password");
      setIcon(eye);
    }
  };

  const submit: SubmitHandler<Login> = async (data) => {
    try {
      const res = await fetch(`${baseUrl}user/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (res.status === 200) {
        const userResp = await fetch(`${baseUrl}user/me`);
        const userData = await userResp.json();
        auth?.setAuthStatus({
          isAuth: true,
          user: userData,
        });
        navigate("/");
      } else if (res.status === 401) {
        setError("root", { type: "custom", message: "" });
      } else if (res.status === 422) {
        setError("root", { type: "wrong-password", message: "" });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Helmet>
        <title>Войти | НЦПТИ</title>
        <meta
          name="description"
          content="Вход на страницу Национального центра информационного противодействия терроризму и экстремизму в образовательной среде и сети Интернет"
        />
        <meta property="og:title" content="Войти | НЦПТИ" />
        <meta
          property="og:description"
          content="Вход на страницу Национального центра информационного противодействия терроризму и экстремизму в образовательной среде и сети Интернет"
        />
        <meta property="og:image" content={Preview} />
        <meta property="og:url" content={href} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="page">
        <form onSubmit={handleSubmit(submit)} className="login-form">
          <h1 className="login-title">Войти</h1>
          <div>
            <input
              type="email"
              placeholder="Введите ваш Email"
              className="login-input"
              {...register("email", { required: true })}
            />
            {errors.email && errors.email.type === "required" && (
              <p className="wrong-message">Это поле обязательно</p>
            )}
          </div>
          <div className="input-password">
            <input
              type={inputType}
              placeholder="Введите пароль"
              className="login-input"
              {...register("password", { required: true })}
            />
            <span className="password-icon" onClick={togglePassword}>
              <img alt="иконка" className="password-icon__img" src={icon} />
            </span>
          </div>
          {errors.password && errors.password.type === "required" && (
            <p className="wrong-message">Это поле обязательно</p>
          )}
          {errors.root && (
            <p className="wrong-message">
              Почта или пароль введены неправильно
            </p>
          )}
          <div className="btns">
            <button className="button login-btn">Войти</button>
          </div>
          <div className="login-support">
            <Link to="/registration" className="login-support__a">
              Регистрация
            </Link>
            <Link to="/forgot-password" className="login-support__a">
              Забыли пароль?
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
