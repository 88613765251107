import React from "react";
import "./cards.scss";
import { useEffect, useState } from "react";
import { baseUrl } from "../../../../constants/constants";
import CardsRouter from "./components/cardsRouter";
import { Link } from "react-router-dom";
import Preview from "../../../../assets/materails_cards/memos-mobile_.webp";
import { Helmet } from "react-helmet-async";
const href =
  typeof window !== "undefined" ? window.location.href : "https://нцпти.рф/";

interface CategoriesInterface {
  id: number;
  name: string;
  type_category: string;
}

interface IBanner {
  id: number;
  name: string;
  file_url: string;
}

const Cards = () => {
  const [banner, setBanner] = useState<IBanner[]>([]);
  const [mobileBanner, setMobileBanner] = useState<IBanner[]>([]);
  const [categories, setCategories] = useState<CategoriesInterface[]>([]);

  useEffect(() => {
    fetch(`${baseUrl}gallery_images?category_id=19`)
      .then((res) => res.json())
      .then((data: IBanner[]) => {
        const banner = data.filter((item) => item.id === 71);
        setBanner(banner);
        console.log(banner);
      });

    fetch(`${baseUrl}gallery_images?category_id=19`)
      .then((res) => res.json())
      .then((data: IBanner[]) => {
        const bannerMobile = data.filter((el) => el.id === 80);
        setMobileBanner(bannerMobile);
      });
  }, []);

  useEffect(() => {
    fetch(`${baseUrl}category?type_category=memo`)
      .then((res) => res.json())
      .then((data) => {
        setCategories(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Карточки для соцсетей | НЦПТИ</title>
        <meta
          name="description"
          content="Скачивайте наши материалы и публикуйте в своих социальных сетях: карточки статей, событий и мероприятий, аналитики, спецпроектов и обучения"
        />
        <meta property="og:title" content="Карточки для соцсетей | НЦПТИ" />
        <meta
          property="og:description"
          content="Скачивайте наши материалы и публикуйте в своих социальных сетях: карточки статей, событий и мероприятий, аналитики, спецпроектов и обучения"
        />
        <meta property="og:image" content={Preview} />
        <meta property="og:url" content={href} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="page-memos">
        <h1 className="main_title">Карточки для соцсетей</h1>
        {banner &&
          banner.map((i) => (
            <section className="title-image">
              <img
                src={`${baseUrl}${i.file_url}`}
                alt="Баннер Наши проекты"
                loading="lazy"
                className="title-image__img"
              />
            </section>
          ))}

        {mobileBanner &&
          mobileBanner.map((e) => (
            <section className="title-image__mobile">
              <img
                src={`${baseUrl}${e.file_url}`}
                alt="Баннер Наши проекты мобильный"
                loading="lazy"
                className="title-image__img-mobile"
              />
            </section>
          ))}

        <div className="page">
          {categories &&
            categories.map((item) => (
              <CardsRouter id={item.id} name={item.name} key={item.id} />
            ))}
        </div>
      </div>
    </>
  );
};

export default Cards;
