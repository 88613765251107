import React, { useEffect, useState } from "react";
import "./projects.scss";
import Paralleli from "../../../assets/projects_cards/parallels_.webp";
import Podvig from "../../../assets/projects_cards/podvig_.webp";
import NujnyeLyudi from "../../../assets/projects_cards/important-mens_.webp";
import Answers from "../../../assets/projects_cards/answers_.webp";
import { baseUrl } from "../../../constants/constants";
import Preview from "../../../assets/cards/projects_.webp";
import { Helmet } from "react-helmet-async";

const href =
  typeof window !== "undefined" ? window.location.href : "https://нцпти.рф/";

interface IBanner {
  id: number;
  name: string;
  file_url: string;
}

const Projects = () => {
  const [banner, setBanner] = useState<IBanner[]>([]);
  const [mobileBanner, setMobileBanner] = useState<IBanner[]>([]);
  const [metrikaCounted, setMetrikaCounted] = useState(false)

  useEffect(() => {
    fetch(`${baseUrl}gallery_images?category_id=19`)
      .then((res) => res.json())
      .then((data: IBanner[]) => {
        const banner = data.filter((item) => item.id === 67);
        setBanner(banner);
        console.log(banner);
      });

    fetch(`${baseUrl}gallery_images?category_id=19`)
      .then((res) => res.json())
      .then((data: IBanner[]) => {
        const bannerMobile = data.filter((el) => el.id === 77);
        setMobileBanner(bannerMobile);
      });
  }, []);

  const handleView = () => {
      if (!metrikaCounted) {
          setMetrikaCounted(true)
          // @ts-ignore
          ym(100105951,'reachGoal','visit-project')
      }
  }

  return (
    <>
      <Helmet>
        <title>Наши проекты | НЦПТИ</title>
        <meta
          name="description"
          content="Проекты по сохранению исторической памяти, образовательная площадка для студентов, ответы на социальные и политические вопросы и другое от команды НЦПТИ"
        />
        <meta property="og:title" content="Наши проекты | НЦПТИ" />
        <meta
          property="og:description"
          content="Проекты по сохранению исторической памяти, образовательная площадка для студентов, ответы на социальные и политические вопросы и другое от команды НЦПТИ"
        />
        <meta property="og:image" content={Preview} />
        <meta property="og:url" content={href} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="page-projects">
        <h1 className="main_title">Наши проекты</h1>
        {banner &&
          banner.map((i) => (
            <section className="title-image">
              <img
                src={`${baseUrl}${i.file_url}`}
                alt="Баннер Наши проекты"
                loading="lazy"
                className="title-image__img"
              />
            </section>
          ))}

        {mobileBanner &&
          mobileBanner.map((e) => (
            <section className="title-image__mobile">
              <img
                src={`${baseUrl}${e.file_url}`}
                alt="Баннер Наши проекты мобильный"
                loading="lazy"
                className="title-image__img-mobile"
              />
            </section>
          ))}

        <div className="page">
          <section className="projects">
            <div className="single-project">
              <a href="http://подвиг.рф" target="_blank"  onClick={handleView}>
                <img
                  src={Podvig}
                  alt="Обложка проекта подвиг.рф"
                  className="project-img"
                />
              </a>
              <div className="project-description">
                <div className="project-description__text">
                  <h2 className="project-description__title">Подвиг.РФ</h2>
                  <p className="project-description__p">
                    Проект, посвящённый сохранению исторической памяти о
                    подвигах и событиях.
                    <br />
                    Художественные рассказы и подкасты о людях, конкурсы для
                    творческих индустрий и волонтёров.
                    <br />
                    Победитель Международной Премии #МЫВМЕСТЕ в номинации
                    «Медиапроект».
                  </p>
                </div>
                <a
                  href="https://подвиг.рф"
                  className="project-description__link"
                  target="_blank"
                  onClick={handleView}
                >
                  Перейти к проекту
                </a>
              </div>
            </div>
          </section>

          <section className="projects">
            <div className="single-project">
              <a href="https://proekt-paralleli.ru" target="_blank" onClick={handleView}>
                <img
                  src={Paralleli}
                  alt="Обложка проекта Параллели"
                  className="project-img"
                />
              </a>
              <div className="project-description">
                <div className="project-description__text">
                  <h2 className="project-description__title">
                    Контент-фабрика «Параллели»
                  </h2>
                  <p className="project-description__p">
                    Образовательная и коммуникационная площадка для студентов и
                    представителей медиа. <br />
                    Специальный приз премии «Серебрянный Лучник» — Юг.
                  </p>
                </div>
                <a
                  href="https://proekt-paralleli.ru"
                  className="project-description__link"
                  target="_blank"
                  onClick={handleView}
                >
                  Перейти к проекту
                </a>
              </div>
            </div>
          </section>

          <section className="projects">
            <div className="single-project">
              <a href="https://vk.com/otvetyshow" target="_blank" onClick={handleView}>
                <img
                  src={Answers}
                  alt="Обложка проекта Ответы"
                  className="project-img"
                />
              </a>
              <div className="project-description">
                <div className="project-description__text">
                  <h2 className="project-description__title">Шоу «Ответы»</h2>
                  <p className="project-description__p">
                    Это шоу, где приглашенные эксперты отвечают на социальные и
                    политические вопросы от студентов. Первый сезон посвящен
                    вопросам медиабезопасности.
                  </p>
                </div>
                <a
                  href="https://vk.com/otvetyshow"
                  className="project-description__link"
                  target="_blank"
                  onClick={handleView}
                >
                  Перейти к проекту
                </a>
              </div>
            </div>
          </section>

          <section className="projects">
            <div className="single-project">
              <a
                href="https://vk.com/video-112267450_456239497"
                target="_blank"
                onClick={handleView}
              >
                <img
                  src={NujnyeLyudi}
                  alt="Обложка проекта Нужные люди"
                  className="project-img"
                />
              </a>
              <div className="project-description">
                <div className="project-description__text">
                  <h2 className="project-description__title">
                    Учебный документальный фильм «Нужные люди»
                  </h2>
                  <p className="project-description__p">
                    Об эффективных форматах работы со школьниками. Фильм
                    предназначен для специалистов, работающих в сфере
                    профилактики негативных явлений в среде несовершеннолетних:
                    учителей, школьных психологов, социальных педагогов, а также
                    для специалистов по молодёжной политике.
                  </p>
                </div>
                <a
                  href="https://vk.com/video-112267450_456239497"
                  className="project-description__link"
                  target="_blank"
                  onClick={handleView}
                >
                  Перейти к проекту
                </a>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Projects;
