import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./video.scss";
import { baseUrl } from "../../../../constants/constants";
import OneVideo from "./components/oneVideo";
import Preview from "../../../../assets/materails_cards/video-mobile_.webp";
import { Helmet } from "react-helmet-async";
const href =
  typeof window !== "undefined" ? window.location.href : "https://нцпти.рф/";

export interface VideoInterface {
  id: number;
  name: string;
  video_url: string;
  create_date: Date;
  category_id: number;
  category_name: string;
}

interface IBanner {
  id: number;
  name: string;
  file_url: string;
}

const Video = () => {
  const [studentVideo, setStudentVideo] = useState<VideoInterface[]>([]);
  const [specialistVideo, setSpecialistVideo] = useState<VideoInterface[]>([]);
  const [parallelsVideo, setParallelsVideo] = useState<VideoInterface[]>([]);
  const [banner, setBanner] = useState<IBanner[]>([]);
  const [mobileBanner, setMobileBanner] = useState<IBanner[]>([]);
  const [currentPage, setCurrentPage] = useState("Ролики");

  useEffect(() => {
    fetch(`${baseUrl}gallery_images?category_id=19`)
      .then((res) => res.json())
      .then((data: IBanner[]) => {
        const banner = data.filter((item) => item.id === 68);
        setBanner(banner);
        console.log(banner);
      });

    fetch(`${baseUrl}gallery_images?category_id=19`)
      .then((res) => res.json())
      .then((data: IBanner[]) => {
        const bannerMobile = data.filter((el) => el.id === 78);
        setMobileBanner(bannerMobile);
      });
  }, []);

  useEffect(() => {
    fetch(`${baseUrl}gallery_video?sort_date=true&category_id=12`)
      .then((res) => res.json())
      .then((data) => {
        setStudentVideo(data);
      });

    fetch(`${baseUrl}gallery_video?sort_date=true&category_id=18`)
      .then((res) => res.json())
      .then((data) => {
        setSpecialistVideo(data);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Видео | НЦПТИ</title>
        <meta
          name="description"
          content="Профилактические и образовательные видео для студентов и специалистов от команды Национального центра информационного противодействия терроризму и экстремизму в образовательной среде и сети Интернет"
        />
        <meta property="og:title" content="Видео | НЦПТИ" />
        <meta
          property="og:description"
          content="Профилактические и образовательные видео для студентов и специалистов от команды Национального центра информационного противодействия терроризму и экстремизму в образовательной среде и сети Интерне"
        />
        <meta property="og:image" content={Preview} />
        <meta property="og:url" content={href} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="page-video">
        <h1 className="main_title">Видео</h1>
        {banner &&
          banner.map((i) => (
            <section className="title-image">
              <img
                src={`${baseUrl}${i.file_url}`}
                alt="Баннер Видео"
                loading="lazy"
                className="title-image__img"
              />
            </section>
          ))}

        {mobileBanner &&
          mobileBanner.map((e) => (
            <section className="title-image__mobile">
              <img
                src={`${baseUrl}${e.file_url}`}
                alt="Баннер Видео мобильный"
                loading="lazy"
                className="title-image__img-mobile"
              />
            </section>
          ))}

        <div className="page">
          <div className="page-btns">
            <Link to="#">
              <button className="button video-btn__active">Ролики</button>
            </Link>
            <Link to="video-parallels">
              <button className="button video-btn">
                Работы Контент-фабрики «Параллели»
              </button>
            </Link>
          </div>
          <h2 className="title-h2 video-containers__title">
            Профилактические ролики для студентов
          </h2>
          <section className="video-containers">
            {studentVideo && studentVideo.length > 0 ? (
              studentVideo.map((item) => <OneVideo key={item.id} {...item} />)
            ) : (
              <h2 className="notFound-title">Видео не найдены</h2>
            )}
          </section>

          <h2 className="title-h2 video-containers__title">
            Образовательные ролики для специалистов
          </h2>
          <section className="video-containers">
            {specialistVideo && specialistVideo.length > 0 ? (
              specialistVideo.map((i) => <OneVideo key={i.id} {...i} />)
            ) : (
              <h2 className="notFound-title">Видео не найдены</h2>
            )}
          </section>
        </div>
      </div>
    </>
  );
};

export default Video;

// ?page=1&page_size=20&sort_date=true&category_id=12
