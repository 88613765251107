import React, {useState} from 'react';
import {ICardsSettings, ICategoryCards} from "../CardsSettings";
import UpdateCard from "../UpdateCard/UpdateCard";
import {baseUrl} from "../../../../../../../constants/constants";
import Modal from "../../../../../../../components/modal/modal";

interface ICardsList{
    cardsList: ICardsSettings,
    category: ICategoryCards[],
    deleteCard: (currentCard: ICardsSettings) => void
}

const CardsList:React.FC<ICardsList> = ({cardsList,category, deleteCard}) => {
    const [showUpdateCardModal, setShowUpdateCardModal] = useState(false)

    const closeModal = () => {
        setShowUpdateCardModal(false)
    }

    return (
        <tr>
            <td className="applications-table__tbody-td">{cardsList.category_name}</td>
            <td className="applications-table__tbody-td">
                <img
                    src={`${baseUrl}${cardsList.file_card_url}`}
                    alt="обложка карточки"
                    className='cards-settings__img'
                />
            </td>
            <td className="applications-table__tbody-td">{cardsList.name}</td>
            <td className="applications-table__tbody-td">
                <a
                    href={`${baseUrl}${cardsList.file_url}`}
                >
                    {cardsList.file_url}
                </a>
            </td>
            <td className="applications-table__tbody-td">
                <button
                    className="button updateBtn"
                    onClick={() => setShowUpdateCardModal(true)}
                >
                    Обновить
                </button>
                {showUpdateCardModal &&
                    <Modal
                        active={showUpdateCardModal}
                        title='Обновите информацию о карточке'
                        сlose={closeModal}
                    >
                        <UpdateCard
                            card={cardsList}
                            category={category}
                            close={closeModal}
                        />
                    </Modal>
                }
                <button
                    className="button deleteBtn"
                    onClick={() => deleteCard(cardsList)}
                >
                    Удалить
                </button>
            </td>
        </tr>
    );
};

export default CardsList;