import React, {useState} from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import {baseUrl} from "../../../../../../../constants/constants";
import {formAdapter} from "../UpdateForum/utils";
import {errorToast, successToast} from "../../../../../../../shared/toastify";

export interface ICreateForum {
    name: string,
    description: string,
    supplement: string,
    forum_location: string,
    forum_visit_format: {
        id: number,
        name: string
    },
    start_date_training: Date,
    end_date_training: Date,
    image: string,
    forum_visit_format_offline: boolean;
    forum_visit_format_online: boolean;
    max_seats: number,
    start_registration_time: string,
    end_registration_time: string,
}

interface Props {
    close: () => void,
    onSuccess: () => void
}

const CreateNewForum = ({close, onSuccess}: Props) => {
    const {
        register,
        handleSubmit,
        formState: {errors},
        setError, watch
    } = useForm<ICreateForum>()

    const handleChange = (event: { target: { files: any }; }) => {
        console.log(event.target.files)
    }

    const submit: SubmitHandler<ICreateForum> = async (data: any) => {
        try {
            const formAdapted: any = formAdapter(data);
            console.log('preFormData', formAdapted)
            const formData = new FormData();
            console.log(formAdapted);

            for (let key in formAdapted) {
                if (key == "image") {
                    if (!!formAdapted[key][0]) {
                        formData.append(key, formAdapted[key][0]);
                    }
                }

                if (!Array.isArray(formAdapted[key]) && key !== "image") {
                    formData.append(key, data[key]);
                }
                if (Array.isArray(formAdapted[key])) {
                    formAdapted[key].forEach((el: number) =>
                        formData.append(key, String(el))
                    );
                }
                if (key.includes('_registration_time')) {
                    formData.set(key, `${data[key]}Z`);
                }
            }

            const res = await fetch(`${baseUrl}forum`, {
                method: 'POST',
                body: formData
            });
            if (res.status === 200) {
                successToast('Мероприятие успешно создано. Обновите страницу');
                res.json().then((body) => {
                    localStorage.setItem('forum_id_for_links', body.id)
                })
                onSuccess()
            } else if (res.status === 401) {
                setError('root', {type: 'custom', message: ''})
                errorToast('Ошибка 401')
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <section>
            <form
                className="regForm"
                onSubmit={handleSubmit(submit)}
            >
                <input
                    type="text"
                    className="regInput"
                    placeholder='Название мероприятия'
                    {...register('name', {required: true})}
                />
                {errors.name && <p className='wrong-message'>Это поле обязательно</p>}
                <textarea
                    className="regInput"
                    placeholder='Описание мероприятия'
                    {...register('description', {required: true})}
                />
                {errors.description && <p className='wrong-message'>Это поле обязательно</p>}
                <textarea
                    className="regInput"
                    placeholder='Дополнение к описанию'
                    {...register('supplement')}
                />
                <input
                    type="text"
                    className="regInput"
                    placeholder='Место проведения мероприятия'
                    {...register('forum_location', {required: true})}
                />
                {errors.forum_location && <p className='wrong-message'>Это поле обязательно</p>}

                <fieldset
                    className='account-label'

                >
                    Формат мероприятия
                    <span>
                    <input
                        type="checkbox"
                        className='regInput__checkbox-galka'
                        value='1'
                        {...register('forum_visit_format_online')}
                    />
                        <label className='account-label__radio__label'>Онлайн</label>
                        </span>
                    <span>
                    <input
                        type="checkbox"
                        className='regInput__checkbox-galka'
                        value='2'
                        {...register('forum_visit_format_offline')}/>
                        <label className='account-label__radio__label'>Очно</label>
                        </span>
                </fieldset>
                {errors.forum_visit_format && <p className='wrong-message'>Это поле обязательно</p>}

                <label className='regLabel'>
                    Дата начала мероприятия
                    <input
                        type="date"
                        className="regInput"
                        {...register('start_date_training', {required: true})}
                    />
                </label>
                {errors.start_date_training && <p className='wrong-message'>Это поле обязательно</p>}
                <label className='regLabel'>
                    Дата окончания мероприятия
                    <input
                        type="date"
                        className="regInput"
                        {...register('end_date_training', {required: true})}
                    />
                </label>
                {
                    (
                        <>
                            <label className='regLabel'>
                                Время начала регистрации
                                <input
                                    type="time"
                                    className="regInput"
                                    {...register('start_registration_time', {required: true})}
                                />
                            </label>
                            <label className='regLabel'>
                                Время конца регистрации
                                <input
                                    type="time"
                                    className="regInput"
                                    {...register('end_registration_time', {required: true})}
                                />
                            </label>
                        </>
                    )
                }
                {errors.end_date_training && <p className='wrong-message'>Это поле обязательно</p>}
                <span className="regInput__file">
                    <p>Изображение мероприятия</p>
                    <input
                        type="file"
                        {...register('image')}
                        accept='image/jpg, image/jpeg,image/webp, image/png'
                    />
                </span>
                <input
                    type="number"
                    className="regInput"
                    placeholder='Количество мест'
                    {...register('max_seats')}
                />
                <button className='button form-btn'>Создать</button>
            </form>
        </section>
    );
};

export default CreateNewForum;