import React from 'react';
import {IBlankOfflineCourse} from "./OfflineTable";

const BlankOffline = ({
    contact_email,
    contact_full_name,
    contact_number_phone,
    event_location,
    topics_discussion,
    desired_time,
    date_creation,
    offline_course
                      }:IBlankOfflineCourse) => {

    const date_blank_offline = new Date(date_creation).toLocaleString('ru', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    });

    const desired_time_event = new Date(desired_time).toLocaleString('ru', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    });

    return (
        <tr>
            <td className='applications-table__tbody-td'>{offline_course.name}</td>
            <td className='applications-table__tbody-td'>{contact_full_name}</td>
            <td className='applications-table__tbody-td'>{contact_email}</td>
            <td className='applications-table__tbody-td'>{contact_number_phone}</td>
            <td className='applications-table__tbody-td'>{event_location}</td>
            <td className='applications-table__tbody-td'>{topics_discussion}</td>
            <td className='applications-table__tbody-td'>{desired_time_event}</td>
            <td className='applications-table__tbody-td'>{date_blank_offline}</td>
        </tr>
    );
};

export default BlankOffline;