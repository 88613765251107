import React, { useEffect, useState } from "react";
import "./metodichki.scss";
import { Link } from "react-router-dom";
import Sborniks from "./components/sborniks/sborniks";
import CheckLists from "./components/check-lists/checkLists";
import { baseUrl } from "../../../../constants/constants";
import Preview from "../../../../assets/materails_cards/manuals-mobile_.webp";
import { Helmet } from "react-helmet-async";
const href =
  typeof window !== "undefined" ? window.location.href : "https://нцпти.рф/";

interface IBanner {
  id: number;
  name: string;
  file_url: string;
}

const Metodichki = () => {
  const [banner, setBanner] = useState<IBanner[]>([]);
  const [mobileBanner, setMobileBanner] = useState<IBanner[]>([]);

  useEffect(() => {
    fetch(`${baseUrl}gallery_images?category_id=19`)
      .then((res) => res.json())
      .then((data: IBanner[]) => {
        const banner = data.filter((item) => item.id === 70);
        setBanner(banner);
        console.log(banner);
      });

    fetch(`${baseUrl}gallery_images?category_id=19`)
      .then((res) => res.json())
      .then((data: IBanner[]) => {
        const bannerMobile = data.filter((el) => el.id === 79);
        setMobileBanner(bannerMobile);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Методические рекомендации | НЦПТИ</title>
        <meta
          name="description"
          content="Регистрация на странице Национального центра информационного противодействия терроризму и экстремизму в образовательной среде и сети Интернет"
        />
        <meta property="og:title" content="Методические рекомендации | НЦПТИ" />
        <meta
          property="og:description"
          content="Регистрация на странице Национального центра информационного противодействия терроризму и экстремизму в образовательной среде и сети Интернет"
        />
        <meta property="og:image" content={Preview} />
        <meta property="og:url" content={href} />
        <meta property="og:type" content="website" />
      </Helmet>
      <h1 className="main_title">Методические рекомендации</h1>
      {banner &&
        banner.map((i) => (
          <section className="title-image">
            <img
              src={`${baseUrl}${i.file_url}`}
              alt="Баннер Наши проекты"
              loading="lazy"
              className="title-image__img"
            />
          </section>
        ))}

      {mobileBanner &&
        mobileBanner.map((e) => (
          <section className="title-image__mobile">
            <img
              src={`${baseUrl}${e.file_url}`}
              alt="Баннер Наши проекты мобильный"
              loading="lazy"
              className="title-image__img-mobile"
            />
          </section>
        ))}

      <div className="page">
        <h2 className="title-h2__manuals">
          Сборники профилактических мероприятий
        </h2>
        <section className="manuals-cards">
          <Sborniks />
        </section>
        <h2 className="title-h2__manuals check-h2">Методички и чек-листы</h2>
        <section className="manuals-cards">
          <CheckLists />
        </section>
      </div>
    </>
  );
};

export default Metodichki;
