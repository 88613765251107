import React from 'react';
import {CheckListInterface} from "../checkLists";
import {baseUrl} from "../../../../../../../constants/constants";

const CheckList = ({id, name, file_url, file_card_url}: CheckListInterface) => {
    const [metrikaCounted, setMetrikaCounted] = React.useState(false)
    const handleView = () => {
        if (!metrikaCounted) {
            setMetrikaCounted(true)
            // @ts-ignore
            ym(100105951,'reachGoal','metodichki-checklist')
        }
    }

    return (
        <>
            <div className="one-checkList">
                <a href={`${baseUrl}${file_url}`} onClick={handleView} target='_blank'>
                    <img src={`${baseUrl}${file_card_url}`} alt="карточка" className='manuals-image'/>
                    <p className='manuals-description'>{name}</p>
                </a>
            </div>
        </>
    );
};

export default CheckList;