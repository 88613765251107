import React, {useEffect, useState} from 'react';
import {baseUrl} from "../../../../../../constants/constants";
import {Link} from "react-router-dom";
import {dwnldCourse} from "../../../../../../components/excel-export/excel-export";
import BlankOnline from "./BlankOnline";

export interface IBlankOnlineCourse {
    id: number,
    email: string,
    full_name: string,
    full_name_declination: string,
    age: number,
    number_phone: string,
    place_of_work: string,
    position_work: string,
    higher_education: boolean,
    buy_certificate: boolean,
    online_course_id: number,
    date_creation: Date,
    online_course: {
        id: number,
        name: string
    }
}

const OnlineTable = () => {
    const [onlineBlanks, setOnlineBlanks] = useState<IBlankOnlineCourse[]>([]);

    useEffect(() => {
        fetch(`${baseUrl}blank_online_course?sort_date=true`)
            .then((res) => res.json())
            .then((data) => {
                setOnlineBlanks(data)
            })
            .catch((err) => {
                console.log(err)
            });
    }, []);

    return (
        <section className="admin-section">
            <h1 className="admin-title__h1-media">Редактирование секции "Заявки"</h1>
            <section className="admin-media__btns-section">
                <Link to='/admin-panel/applications/forums'>
                    <button className="button admin-media__btn">Форумы</button>
                </Link>
                <Link to='#'>
                    <button className="button admin-media__btn-active">Онлайн-курсы</button>
                </Link>
                <Link to='/admin-panel/applications/offline'>
                    <button className="button admin-media__btn">Оффлайн-курсы</button>
                </Link>
                <Link to='/admin-panel/applications/obzor'>
                    <button className="button admin-media__btn">Обзор.НЦПТИ</button>
                </Link>
            </section>
            <div className="one-table">
                <div className="admin-buttons">
                    <h3 className='admin-title__h3'>Онлайн-курсы</h3>
                    <button className="button admin-btn" onClick={dwnldCourse}>Excel</button>
                </div>
                <table
                    className='applications-table'
                >
                    <thead className='applications-table__thead'>
                    <tr>
                        <th className='applications-table__thead-th'>Название курса</th>
                        <th className='applications-table__thead-th'>Email</th>
                        <th className='applications-table__thead-th'>Имя</th>
                        <th className='applications-table__thead-th'>Имя в родительном падеже</th>
                        <th className='applications-table__thead-th'>Возраст</th>
                        <th className='applications-table__thead-th'>Телефон</th>
                        <th className='applications-table__thead-th'>Место работы</th>
                        <th className='applications-table__thead-th'>Должность</th>
                        <th className='applications-table__thead-th'>Высшее образование</th>
                        <th className='applications-table__thead-th'>Сертификат</th>
                        <th className='applications-table__thead-th'>Дата подачи</th>
                    </tr>
                    </thead>
                    {onlineBlanks.map((item) =>
                        <tbody className='applications-table__tbody' key={item.id}>
                        <BlankOnline {...item}/>
                        </tbody>
                    )}
                </table>
            </div>

        </section>
    );
};

export default OnlineTable;