import React from 'react';
import {CardsInterface} from "./cardsRouter";
import {baseUrl} from "../../../../../constants/constants";

const OneCard = ({id, name, file_url, file_card_url}: CardsInterface) => {
    const [metrikaCounted, setMetrikaCounted] = React.useState(false)
    const handleView = () => {
        if (!metrikaCounted) {
            console.log(id, name)
            setMetrikaCounted(true)
            switch (id) {
                case 1:
                    // @ts-ignore
                    ym(100105951,'reachGoal','card-profilaktika')
                break;
                case 2:
                    // @ts-ignore
                    ym(100105951,'reachGoal','card-event')
                break;
                case 3:
                    // @ts-ignore
                    ym(100105951,'reachGoal','card-analitik')
                break;
                case 4:
                    // @ts-ignore
                    ym(100105951,'reachGoal','card-mediasecurity')
                break;
                case 5:
                    // @ts-ignore
                    ym(100105951,'reachGoal','card-antiterror')
                break;
                case 6:
                    // @ts-ignore
                    ym(100105951,'reachGoal','card-antiextreme')
                break;
                case 7:
                    // @ts-ignore
                    ym(100105951,'reachGoal','card-info')
                break;
                case 8:
                    // @ts-ignore
                    ym(100105951,'reachGoal','card-education')
                break;
                case 9:
                    // @ts-ignore
                    ym(100105951,'reachGoal','card-special')
                break;
            }
        }
    }
    return (
        <div className="oneCard">
            <a href={`${baseUrl}${file_url}`} onClick={handleView}>
                <img
                    className='cards__img'
                    src={`${baseUrl}${file_card_url}`}
                    alt="Обложка карточки"
                    loading='lazy'
                />
                <p className='oneCard-p'>{name}</p>
                </a>
        </div>
    );
};

export default OneCard;