import React, {useEffect} from 'react';
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {baseUrl} from "../../../constants/constants";


interface IToken {
    tmp_key: string
}
const No = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const tmp_key = searchParams.get('token')

    // const submit: SubmitHandler<IToken> = async (data) => {
    //     try{
    //         const res = await fetch(`${baseUrl}blank_forum/mailing/no`,{
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({tmp_key})
    //         });
    //         if(res.status === 200){
    //             console.log('goooooood')
    //             console.log(tmp_key)
    //         } else if(res.status === 401){
    //             setError('root', {type: 'custom', message:''})
    //         }
    //     } catch (err){
    //         console.error(err)
    //     }
    // }

    useEffect(() => {
        const res = fetch(`${baseUrl}blank_forum/mailing/no`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({tmp_key})
        })
    }, []);

    return (
        <div className={'approval-page'}>
            <h2 className={'approval-page__title'}>Вы отвергли своё участие в форуме "Планёрка профилактики" и переведены на онлайн. Вам будет выслано письмо со ссылками</h2>
            <Link to={'/'}>
                <button className={'button'}>На главную</button>
            </Link>
        </div>
    );
};

export default No;