import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { baseUrl } from "../../../../../constants/constants";
import "../../onlineCourses.scss";
import { useAuth } from "../../../../../components/authProvider/authProvider";
import { successToast } from "../../../../../shared/toastify";

export function checkErrorText(inputName: string, errors: any) {
  return errors?.[inputName] && errors?.[inputName]?.message;
}

interface CourseForm {
  online_course_id: number;
  email: string;
  full_name: string;
  age: number;
  full_name_declination: string;
  number_phone: string;
  place_of_work: string;
  position_work: string;
  higher_education: string;
  buy_certificate: boolean;
}

interface Props {
  online_course_id: number;
  close: () => void;
}

const CourseRegistrationForm = ({ online_course_id, close }: Props) => {
  const isAuth = useAuth();
  const [metrikaCounted, setMetrikaCounted] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<CourseForm>();

  const sendMetrika = (id: number, is_paid: boolean) => {
    if (metrikaCounted) {
      return
    }
    const conditions = id + "_" + is_paid;
    switch (conditions) {
      case "8_true":
        // @ts-ignore
        ym(100105951, "reachGoal", "course-paid-internet");
        break;
      case "8_false":
        // @ts-ignore
        ym(100105951, "reachGoal", "course-free-internet");
        break;
      case "3_true":
        // @ts-ignore
        ym(100105951, "reachGoal", "course-paid-coaching");
        break;
      case "3_false":
        // @ts-ignore
        ym(100105951, "reachGoal", "course-free-coaching");
        break;
      case "2_true":
        // @ts-ignore
        ym(100105951, "reachGoal", "course-paid-antiterror");
        break;
      case "2_false":
        // @ts-ignore
        ym(100105951, "reachGoal", "course-free-antiterror");
        break;
    }
    setMetrikaCounted(true)
  };
  const submit: SubmitHandler<CourseForm> = async (data) => {
    try {
      const res = await fetch(`${baseUrl}blank_online_course`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...data, online_course_id: online_course_id }),
      });
      if (res.status === 200) {
        sendMetrika(online_course_id, data.buy_certificate);
        localStorage.setItem("form", "accepted");
        successToast("Форма успешно отправлена");
        close();
      } else if (res.status === 401) {
        setError("root", { type: "custom", message: "" });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(submit)} className="regForm">
        <input
          type="text"
          className="regInput"
          placeholder="ФИО"
          {...register("full_name", {
            required: true,
            value: isAuth?.authState.user?.full_name,
          })}
        />
        {errors.full_name && (
          <p className="wrong-message">Это поле обязательно</p>
        )}
        <input
          type="text"
          className="regInput"
          placeholder="ФИО в родительном падеже"
          {...register("full_name_declination", {
            required: true,
            value: isAuth?.authState.user?.full_name_declination,
          })}
        />
        {errors.full_name_declination && (
          <p className="wrong-message">Это поле обязательно</p>
        )}
        <input
          type="text"
          className="regInput"
          placeholder="Возраст"
          {...register("age", {
            required: true,
            value: isAuth?.authState.user?.age,
          })}
        />
        {errors.age && <p className="wrong-message">Это поле обязательно</p>}
        <input
          type="text"
          className="regInput"
          defaultValue={
            isAuth?.authState.user?.number_phone == null
              ? "+7"
              : `+${isAuth?.authState.user?.number_phone}`
          }
          placeholder="Номер телефона"
          {...register("number_phone", {
            required: true,
            validate: {
              validatePhone: (v) =>
                /\+7\d{3}\d{3}\d{2}\d{2}/.test(v) ||
                "Введите номер телефона, начиная с +7",
            },
          })}
        />
        {errors.number_phone && (
          <p className="wrong-message">
            {checkErrorText("number_phone", errors)}
          </p>
        )}
        {errors.number_phone && (
          <p className="wrong-message">Это поле обязательно</p>
        )}

        <input
          type="email"
          className="regInput"
          placeholder="Email"
          {...register("email", {
            required: true,
            value: isAuth?.authState.user?.email,
          })}
        />
        {errors.email && <p className="wrong-message">Это поле обязательно</p>}
        <input
          type="text"
          className="regInput"
          placeholder="Место работы"
          {...register("place_of_work", {
            required: true,
            value: isAuth?.authState.user?.place_of_work,
          })}
        />
        {errors.place_of_work && (
          <p className="wrong-message">Это поле обязательно</p>
        )}
        <input
          type="text"
          className="regInput"
          placeholder="Должность"
          {...register("position_work", {
            required: true,
            value: isAuth?.authState.user?.position_work,
          })}
        />
        {errors.position_work && (
          <p className="wrong-message">Это поле обязательно</p>
        )}
        <span className="regInput__checkbox">
          <input
            type="checkbox"
            className="regInput__checkbox-galka"
            {...register("higher_education")}
          />
          <label>Наличие высшего образования</label>
        </span>
        <span className="regInput__checkbox">
          <input
            type="checkbox"
            className="regInput__checkbox-galka"
            {...register("buy_certificate")}
          />
          <label>Хочу приобрести удостоверение</label>
        </span>
        <button className="button form-btn">Отправить</button>
        {errors?.root && <p className="wrong-message">{errors.root.message}</p>}
      </form>
    </div>
  );
};

export default CourseRegistrationForm;

// value: `+${isAuth?.authState.user?.number_phone}`
