import React from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import {baseUrl} from "../../../../../../constants/constants";
import {errorToast, successToast} from "../../../../../../shared/toastify";
import {IPrivateForums} from "../../PrivateForumsPage";

interface INewInfoPrivateForum{
    name: string,
    description: string,
    forum_location: string,
    is_active: boolean,
    start_date_training: Date,
    end_date_training: Date
}

interface ICurrentPForum{
    p_fourm: IPrivateForums;
    close:() => void
}

const UpdateOnlineCourse: React.FC<ICurrentPForum> = ({p_fourm, close}) => {
    const{register,
        handleSubmit,
        formState:{errors},
        setError} = useForm<INewInfoPrivateForum>({
        defaultValues:{
            name: p_fourm.name,
            description: p_fourm.description,
            start_date_training: p_fourm.start_date_training
        }
    })

    const forum_start_date = new Date(p_fourm.start_date_training).toLocaleString(
        "ru",
        {
            month: "long",
            day: "numeric",
            year: "numeric",
        }
    );

    const forum_end_date = new Date(p_fourm.end_date_training).toLocaleString(
        "ru",
        {
            month: "long",
            day: "numeric",
            year: "numeric",
        }
    );

    const submit: SubmitHandler<INewInfoPrivateForum> = async (data: any) => {
        try{
            const res = await  fetch(`${baseUrl}private_forum/${p_fourm.id}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            if (res.status === 200){
                successToast('Инофрмация о форуме обновлена. Пожаулйста, обновите страницу!')
                close()
            } else if (res.status === 401){
                setError('root', {type: 'custom', message: ''})
                errorToast('Ошибка 401')
            }
        } catch (err){
            console.log(err)
        }
    }

    return (
        <form
            className='regForm'
            onSubmit={handleSubmit(submit)}
        >
            <textarea
                className="regInput"
                {...register('name')}
            />
            <textarea
                className="regInput"
                {...register('description')}
            />
            <input
                className="regInput"
                type='text'
                {...register('forum_location')}
            />
            <label className="regLabel">
                Дата начала мероприятия
                <input
                    type="date"
                    className="regInput"
                    defaultValue={forum_start_date}
                    {...register("start_date_training")}
                />
            </label>
            <label className="regLabel">
                Дата окончания мероприятия
                <input
                    type="date"
                    className="regInput"
                    defaultValue={forum_end_date}
                    {...register("end_date_training")}
                />
            </label>
            <span className="regInput__checkbox">
        <input
            type="checkbox"
            className="regInput__checkbox-galka"
            {...register("is_active")}
        />
        <label>Сделать активным?</label>
      </span>
            <button className='button form-btn'>Обновить</button>
        </form>
    );
};

export default UpdateOnlineCourse;