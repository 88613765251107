import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { baseUrl } from "../../../constants/constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import Logo from "../../../assets/logo.jpg";
import { Helmet } from "react-helmet-async";
const href =
  typeof window !== "undefined" ? window.location.href : "https://нцпти.рф/";

interface ResetPasswordInterface {
  password_reset_token: string;
  new_password: string;
  confirm_password: string;
}

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
    getValues,
  } = useForm<ResetPasswordInterface>();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const password_reset_token = searchParams.get("token");

  const submit: SubmitHandler<ResetPasswordInterface> = async (data) => {
    try {
      const res = await fetch(`${baseUrl}user/reset_password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...data, password_reset_token }),
      });
      if (res.status === 200) {
        navigate("/");
      } else if (res.status === 401) {
        setError("root", { type: "custom", message: "" });
      } else if (res.status === 422) {
        setError("root", {
          type: "wrong-password",
          message:
            "Пароль должен состоять минимум из 8 символов, включать в себя спецсимволы, цифры и заглавные буквы",
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Helmet>
        <title>Восстановление пароля | НЦПТИ</title>
        <meta
          name="description"
          content="Восстановление пароля на странице Национального центра информационного противодействия терроризму и экстремизму в образовательной среде и сети Интернет"
        />
        <meta property="og:title" content="Восстановление пароля | НЦПТИ" />
        <meta
          property="og:description"
          content="Восстановление пароля на странице Национального центра информационного противодействия терроризму и экстремизму в образовательной среде и сети Интернет"
        />
        <meta property="og:image" content={Logo} />
        <meta property="og:url" content={href} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div>
        <form onSubmit={handleSubmit(submit)} className="login-form">
          <h1 className="login-title">Введите новый пароль</h1>
          <input
            type="text"
            className="login-input"
            placeholder="Введите новый пароль"
            {...register("new_password", { required: true })}
          />
          {errors.new_password && (
            <p className="wrong-message">Это поле обязательно</p>
          )}
          <input
            className="login-input"
            placeholder="Подтвердите пароль"
            {...register("confirm_password", {
              required: true,
              validate: (v) =>
                v === watch("new_password") || "Пароли не совпадают",
            })}
          />
          {errors.confirm_password && (
            <p className="wrong-message">Пароли не совпадают</p>
          )}
          <button className="button login-btn">Готово</button>
          {errors?.root && (
            <p className="wrong-message">{errors.root.message}</p>
          )}
        </form>
      </div>
    </>
  );
};

export default ResetPassword;
