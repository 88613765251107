import React, {useEffect, useState} from 'react';
import './PrivateForumsPagePage.scss'
import {baseUrl} from "../../../../constants/constants";
import Modal from "../../../../components/modal/modal";
import {successToast} from "../../../../shared/toastify";
import PrivateForumsList from "./components/PrivateForumsList/PrivateForumsList";
import CreatePrivateForum from "./components/CreatePrivateForum/CreatePrivateForum";
import privateForumsList from "./components/PrivateForumsList/PrivateForumsList";
import {dwnldForums, dwnldOfflineCourse, dwnldPrivateForum} from "../../../../components/excel-export/excel-export";
import BlankForum from "../ApplicationsPage/components/GetBlankForum/BlankForum";
import GetBlanks from "./components/GetBlanks/GetBlanks";

export interface IPrivateForums{
    id: number,
    name: string,
    description: string,
    is_active: boolean,
    start_date_training: Date,
    end_date_training: Date,
    forum_location: string,
    private_link: string
}

export interface IPrivateForumBlanks{
    id: number,
    full_name: string,
    number_phone: string,
    university: string,
    position_work: string,
    gender: boolean,
    private_forum_id: number,
}

const PrivateForumsPage = () => {
    const [showPrivateModal, setShowPrivateModal] = useState(false);
    const [privateForums, setPrivateForums] = useState<IPrivateForums[]>([]);
    const [privateBlanks, setPrivateBlanks] = useState<IPrivateForumBlanks[]>([])

    useEffect(() => {
        fetch(`${baseUrl}private_blank_forum`)
            .then((res) => res.json())
            .then((data) => {
                setPrivateBlanks(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);

    useEffect(() => {
        fetch(`${baseUrl}private_forum?sort_date=true`)
            .then((res) => res.json())
            .then((data) => {
                setPrivateForums(data)
            })
            .catch((err) => {
                console.log(err)
            });
    }, []);

    async function deleteCurrentPForum(id: number){
        try{
            const res = await fetch(`${baseUrl}private_forum/${id}`, {
                method: 'DELETE'
            });
            successToast('Приватный форум удалён')
            return res.ok
        } catch (err){
            console.log(err)
        }
    }

    const deletePForum = (id: number) => {
        setPrivateForums(privateForums.filter((privateForum) => privateForum.id !== id))
    }

    const updatePForumStatus = (currentPForum: IPrivateForums) => {
        deleteCurrentPForum(currentPForum.id);
        setPrivateForums([...privateForums]);
        deletePForum(currentPForum.id)
    }

    const closeModal = () => {
        setShowPrivateModal(false);
    }

    return (
        <>
            <h1 className="admin-title__h1">Редактирование секции "Приватные форумы"</h1>
            <div className="admin-buttons">
                <p className="admin-title__p">Добавить приватный форум:</p>
                <button
                    className="button admin-btn"
                    onClick={() => setShowPrivateModal(true)}
                >
                    Добавить
                </button>
                {showPrivateModal &&
                    <Modal
                        active={showPrivateModal}
                        title='Добавьте информацию о приватном форуме'
                        сlose={closeModal}
                    >
                        <CreatePrivateForum close={closeModal}/>
                    </Modal>
                }
            </div>

            {/*Список форумов*/}
            <h2 className="admin-title__h2">Список приватных форумов: </h2>
            <div className="admin-div">
                {privateForums.map((i) => (
                    <PrivateForumsList
                        key={i.id}
                        p_forums={i}
                        deletePForum={updatePForumStatus}
                    />
                ))}
            </div>

            {/*Таблица заявок на приватные форумы*/}
            <div className="admin-buttons">
                <h3 className='admin-title__h3'>Заявки</h3>
                <button className="button admin-btn" onClick={() => {
                    dwnldPrivateForum(privateBlanks[0]?.private_forum_id || 1)
                }}>Excel</button>
            </div>
            <table className='applications-table'>
                <thead className='applications-table__thead'>
                <tr>
                    <th className='applications-table__thead-th'>id</th>
                    <th className='applications-table__thead-th'>Имя</th>
                    <th className='applications-table__thead-th'>Телефон</th>
                    <th className='applications-table__thead-th'>ВУЗ</th>
                    <th className='applications-table__thead-th'>Должность</th>
                    <th className='applications-table__thead-th'>Пол</th>
                    <th className='applications-table__thead-th'>Номер форума</th>
                </tr>
                </thead>
                {privateBlanks.map((item) =>
                    <tbody className='applications-table__tbody' key={item.id}>
                        <GetBlanks {...item}/>
                    </tbody>
                )}
            </table>

        </>
    );
};

export default PrivateForumsPage;