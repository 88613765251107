import React from 'react';
import {JournalInterface} from "../catalog";
import {baseUrl} from "../../../../../../constants/constants";

const OneJournal = ({id, name, file_url, file_card_url, year}: JournalInterface) => {
    const [metrikaCounted, setMetrikaCounted] = React.useState(false)
    const sendMetrika = () => {
        if (!metrikaCounted) {
            setMetrikaCounted(true)
            // @ts-ignore
            ym(100105951,'reachGoal','obzor-'+year)
        }
    }
    return (
        <div className='oneJournal'>
            <a href={`${baseUrl}${file_url}`} target='_blank' download={false} onClick={sendMetrika}>
                <img
                    className='journal-image'
                    src={`${baseUrl}${file_card_url}`}
                    alt="Выпуск журнала Обзор.НЦПТИ"/>
            </a>
        </div>
    );
};

export default OneJournal;