import React, {useEffect, useState} from 'react';
import {baseUrl} from "../../../../../../constants/constants";
import {dwnldForums} from "../../../../../../components/excel-export/excel-export";
import BlankForum from "./BlankForum";
import {Link} from "react-router-dom";

export interface IBlankForum {
    id: number,
    email: string,
    full_name: string,
    full_name_declination: string,
    age: number,
    number_phone: string,
    region_residence: string,
    place_of_work: string,
    position_work: string,
    travel_certificate: boolean,
    forum_id: number,
    visit_format: {
        id: 0,
        name: string,
    },
    forum: {
        id: number,
        name: string
    }
    date_creation: Date,
    questions: string,
    is_approved: boolean
}

const ForumTable = () => {
    const [forumBlanks, setForumBlanks] = useState<IBlankForum[]>([]);


    const fetchForumBlanks = () => {
        fetch(`${baseUrl}blank_forum?sort_date=true&page=1&page_size=100000`)
            .then((res) => res.json())
            .then((data) => {
                setForumBlanks(data)
            })
            .catch((err) => {
            });
    }

    useEffect(() => {
        fetchForumBlanks()
    }, []);

    return (
            <section className="admin-section">
                <h1 className="admin-title__h1-media">Редактирование секции "Заявки"</h1>
                <section className="admin-media__btns-section">
                    <Link to='#'>
                        <button className="button admin-media__btn-active">Форумы</button>
                    </Link>
                    <Link to='/admin-panel/applications/online'>
                        <button className="button admin-media__btn">Онлайн-курсы</button>
                    </Link>
                    <Link to='/admin-panel/applications/offline'>
                        <button className="button admin-media__btn">Оффлайн-курсы</button>
                    </Link>
                    <Link to='/admin-panel/applications/obzor'>
                        <button className="button admin-media__btn">Обзор.НЦПТИ</button>
                    </Link>
                </section>
        <div className="one-table">
            <div className="admin-buttons">
                <h3 className='admin-title__h3'>Форумы</h3>
                <button className="button admin-btn" onClick={() => {
                    dwnldForums(forumBlanks[0]?.forum.id || 1);
                }}>Excel
                </button>
            </div>
            <table className='applications-table'>
                <thead className='applications-table__thead'>
                <tr>
                    <th className='applications-table__thead-th'>Название форума</th>
                    <th className='applications-table__thead-th'>Email</th>
                    <th className='applications-table__thead-th'>Имя</th>
                    <th className='applications-table__thead-th'>Имя в родительном падеже</th>
                    <th className='applications-table__thead-th'>Возраст</th>
                    <th className='applications-table__thead-th'>Телефон</th>
                    <th className='applications-table__thead-th'>Регион</th>
                    <th className='applications-table__thead-th'>Место работы</th>
                    <th className='applications-table__thead-th'>Должность</th>
                    <th className='applications-table__thead-th'>Командировочное</th>
                    <th className='applications-table__thead-th'>Формат форума</th>
                    <th className='applications-table__thead-th'>Дата подачи</th>
                </tr>
                </thead>
                {forumBlanks.map((item) =>
                    <tbody className='applications-table__tbody' key={item.id}>
                    <BlankForum
                        {...item}
                        onUpdate={fetchForumBlanks}
                    />
                    </tbody>
                )}
            </table>
        </div>
    </section>
    );
};

export default ForumTable;