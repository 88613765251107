import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext, useCallback,
} from "react";
import { type Dispatch } from "react";
import { baseUrl } from "../../constants/constants";
import { AccountInterface } from "../../pages/User/AccountPage/account";

interface IAuthProvider {
  children: ReactNode;
}

interface IAuthState {
  isAuth: boolean;
  user: AccountInterface | null;
}

interface IAuthContext {
  authState: IAuthState;
  logOut: () => void;
  setAuthStatus: React.Dispatch<
    React.SetStateAction<{
      isAuth: boolean;
      user: AccountInterface | null;
    }>
  >;
  updateUser: (userData: Partial<AccountInterface>) => void;
  authRequest: () => void
}

const AuthContext = createContext<IAuthContext | null>(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<IAuthProvider> = ({ children }) => {
  const [authState, setAuthStatus] = useState<IAuthState>({
    isAuth: false,
    user: null,
  });
  const [isLoading, setLoadingStatus] = useState(false);

  const logOut = async () => {
    const logOutRequest = await fetch(`${baseUrl}user/logout`);
    if (logOutRequest.ok) {
      setAuthStatus({
        ...authState,
        isAuth: false,
      });
    }
  };

  const updateUser = async (updateData: Partial<AccountInterface>) => {
    const userUpdateData = await fetch(`${baseUrl}user/me/update`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(updateData),
    });
    if (userUpdateData.ok) {
      const result = (await userUpdateData.json()) as AccountInterface | null;
      setAuthStatus({
        ...authState,
        user: result,
      });
    }
  };

  const authRequest = useCallback(async () => {
    try {
      const res = await fetch(`${baseUrl}user/me`);
      if (res.status === 200) {
        const userInfo = await res.json();
        setAuthStatus({
          ...authState,
          isAuth: true,
          user: userInfo,
        });
      } else if (res.status === 401) {
        setAuthStatus({
          ...authState,
          isAuth: false,
          user: authState.user,
        });
      }
      setLoadingStatus(true);
    } catch (err) {
      console.error(err);
    }
  }, [])

  useEffect(() => {
    authRequest();
  }, []);

  return (
    <AuthContext.Provider
      value={{ authState, logOut, setAuthStatus, updateUser, authRequest }}
    >
      {isLoading && children}
    </AuthContext.Provider>
  );
};
